import React from 'react'
import Input from '../Input'
import {
  Box,
  useMediaQuery,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'

export default ({ start, end, readOnly, onStartChange, onEndChange }) => {
  const { breakpoints, spacing } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  return (
    <Input label="Dates de la manifestation (Attention : renseignez la date de fin avant la date de début)">
      <Box display="flex" flexDirection={largeScreen ? 'row' : 'column'}>
        <Paper style={{ padding: spacing(1), maxWidth: '325px' }}>
          <Typography variant="body1" display="block" align="center">
            Début
          </Typography>
          <Divider />
          <DatePicker
            disableToolbar
            variant="static"
            clearLabel="vider"
            cancelLabel="annuler"
            value={start}
            maxDate={end}
            onChange={e => !readOnly && onStartChange(e)}
          />
        </Paper>
        <Paper
          style={{
            padding: spacing(1),
            marginLeft: largeScreen ? spacing(3) : 0,
            marginTop: largeScreen ? 0 : spacing(2),
            maxWidth: '325px',
          }}
        >
          <Typography variant="body1" display="block" align="center">
            Fin
          </Typography>
          <Divider />
          <DatePicker
            disableToolbar
            variant="static"
            clearLabel="vider"
            cancelLabel="annuler"
            value={end}
            minDate={start}
            onChange={e => !readOnly && onEndChange(e)}
          />
        </Paper>
      </Box>
    </Input>
  )
}
