const routes = ['Manifestation', 'Event', 'Place', 'Organizer']

class APIError extends Error {
  constructor() {
    super('Une erreur est survenue')
  }
}

const createFormData = item => {
  const formData = new FormData()
  Object.entries(item).forEach(([key, value]) => {
    switch (key) {
      case 'start':
      case 'end':
      case 'date':
        if (value) formData.append(key, value.toISOString())
        else formData.append(key, null)
        break
      case 'organizers':
      case 'openings':
      case 'places':
      case 'events':
        formData.append(key, JSON.stringify(value))
        break
      case 'manifestationId':
        if (value !== null) formData.append(key, value)
      case 'password':
        if (value && value.length) formData.append(key, value)
        break
      default:
        formData.append(key, value)
        break
    }
  })
  return formData
}

const computeDates = (item, route) => {
  switch (route) {
    case 'Manifestation':
      return {
        ...item,
        start: item.start ? new Date(item.start) : null,
        end: item.end ? new Date(item.end) : null,
      }
    case 'Event':
      return {
        ...item,
        date: item.date ? new Date(item.date) : null,
      }
    default:
      return item
  }
}

export class API {
  constructor(apiUrl) {
    this.apiUrl = apiUrl
    routes.forEach(route => {
      // get all
      this[`getAll${route}s`] = async () => {
        const url = `${this.apiUrl}/${route.toLowerCase()}s`
        const res = await fetch(url)
        const json = await res.json()
        return json.map(i => computeDates(i, route))
      }
      // get by id
      this[`get${route}ById`] = async id => {
        const url = `${this.apiUrl}/${route.toLowerCase()}s/${id}`
        const res = await fetch(url)
        const json = await res.json()
        return computeDates(json, route)
      }
      // delete by id
      this[`delete${route}`] = async id => {
        try {
          const token = localStorage.getItem('token')
          const url = `${this.apiUrl}/${route.toLowerCase()}s/${id}`
          const res = await fetch(url, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          const json = await res.json()
          return json
        } catch (err) {
          throw new APIError()
        }
      }
      // create
      this[`create${route}`] = async item => {
        try {
          const token = localStorage.getItem('token')
          const url = `${this.apiUrl}/${route.toLowerCase()}s`
          const formData = createFormData(item)
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          })
          const json = await res.json()
          return computeDates(json, route)
        } catch (err) {
          throw new APIError()
        }
      }
      // update
      this[`update${route}`] = async (item, keepImage) => {
        try {
          const token = localStorage.getItem('token')
          let url = `${this.apiUrl}/${route.toLowerCase()}s/${item.id}`
          if (keepImage) url = url + '/1'
          const formData = createFormData(item)
          const res = await fetch(url, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          })
          const json = await res.json()
          return computeDates(json, route)
        } catch (err) {
          throw new APIError()
        }
      }
    })
  }

  async getMapData() {
    const url = `${this.apiUrl}/app/mapData`
    const res = await fetch(url)
    const json = await res.json()
    return json.map(p => {
      const manifestations = p.manifestations.map(m => {
        const events = m.events.map(e => computeDates(e, 'Event'))
        return { ...computeDates(m, 'Manifestation'), events }
      })
      return { ...p, manifestations }
    })
  }

  async getAppManifestations() {
    const url = `${this.apiUrl}/app/manifestations`
    const res = await fetch(url)
    const json = await res.json()
    return json.map(m => {
      const events = m.events.map(e => computeDates(e, 'Event'))
      return { ...computeDates(m, 'Manifestation'), events }
    })
  }

  async login(data) {
    const url = `${this.apiUrl}/auth`
    const formData = createFormData(data)
    const res = await fetch(url, {
      method: 'POST',
      body: formData,
    })
    const json = await res.json()
    return json
  }

  async tokenAuth() {
    const token = localStorage.getItem('token')
    const url = `${this.apiUrl}/auth`
    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (res.status === 200) {
        const json = await res.json()
        return json
      } else return { user: null }
    } catch (err) {
      return { user: null }
    }
  }

  async createIssue(data) {
    const token = localStorage.getItem('token')
    const url = `${this.apiUrl}/issues`
    const formData = createFormData(data)
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
    const json = await res.json()
    return json
  }

  async generateNewsletter(year, month, promotedId) {
    const url = `${this.apiUrl}/newsletter/${year}/${month}/${promotedId}`
    const res = await fetch(url)
    const text = await res.text()
    return text
  }
}
