import React from 'react'
import { Box, Typography, Divider } from '@material-ui/core'

export default ({ title }) => (
  <>
    <Box mb={1} mt={2}>
      <Typography color="textPrimary" variant="h5">
        {title}
      </Typography>
    </Box>
    <Divider />
  </>
)
