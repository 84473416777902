import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'

export default ({ text }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={11}
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography>{text}</Typography>
      </Box>
    </Box>
  )
}
