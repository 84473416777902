export const places = {
  FILTERS: {
    TODAY: 'today',
    HIGHLIGHTED: 'highlighted',
    OVERSOON: 'oversoon',
    CURRENT: 'current',
    UPCOMING: 'upcoming',
    PERENNE: 'perenne',
    EMPTY: 'empty',
  },
}
