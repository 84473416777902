import React, { useReducer, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  useMediaQuery,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import TextField from '../TextField'
import useStore from '../../store'

const DialogView = ({ onSubmit, onClose }) => {
  const initialState = {
    title: '',
    description: '',
  }
  const [state, dispatch] = useReducer((state, { type, payload }) => {
    switch (type) {
      case 'title':
      case 'description':
        return { ...state, [type]: payload }
      case 'reset':
        return initialState
      default:
        break
    }
  }, initialState)

  const handleClose = () => {
    dispatch({ type: 'reset' })
    onClose()
  }

  const handleSubmit = () => {
    onSubmit(state)
  }

  const submitDisabled = () => {
    return state.title === '' || state.description === ''
  }

  return (
    <>
      <DialogTitle>Signaler un problème</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            label="Titre du problème"
            value={state.title}
            mt={2}
            onChange={e => dispatch({ type: 'title', payload: e })}
          />
          <TextField
            label="Description du problème"
            value={state.description}
            multiline
            rows={6}
            mt={2}
            onChange={e => dispatch({ type: 'description', payload: e })}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Annuler
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          disabled={submitDisabled()}
        >
          Valider
        </Button>
      </DialogActions>
    </>
  )
}

export default ({ open, onClose }) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const [saving, setSaving] = useState(false)
  const createIssue = useStore(state => state.createIssue)

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = async newIssue => {
    setSaving(true)
    await createIssue(newIssue)
    handleClose()
    setSaving(false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={props => (
        <Paper {...props} style={{ width: largeScreen ? '60%' : '100%' }} />
      )}
      maxWidth="md"
    >
      {saving && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={11}
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
        >
          <Typography>Envoi du problème...</Typography>
        </Box>
      )}
      <DialogView onSubmit={handleSubmit} onClose={handleClose} />
    </Dialog>
  )
}
