//Colors used in the app
export const colors = {
  darkColor: '#0A1F32',
  eventColor: '#FFF200',
  upcoming: '#1592E6',
  today: '#FB7826',
  current: '#FFF200',
  oversoon: '#FB263F',
  empty: '#A7B0B8',
  favs: '#F1F1F1',
  perenne: '#219D83',
}
