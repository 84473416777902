import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Paper,
  Box,
  useMediaQuery,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Edit, Save, Delete } from '@material-ui/icons'

import { constants } from '@readymade/shared'
import DetailTitle from '../../components/DetailTitle'
import TextField from '../../components/TextField'
import Dropzone from '../../components/Dropzone'
import SavingBox from '../../components/SavingBox'

import createReducer from './reducer'
import useStore from '../../store'
import Openings from '../../components/Openings'
import Places from '../../components/Places'

const {
  organizer: { ROLE },
} = constants

const useStyles = makeStyles(theme => ({
  root: {},
  inputGroup: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    maxWidth: theme.breakpoints.values.md,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  userInput: {
    width: theme.breakpoints.values.md / 2 - theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  switch: {
    marginTop: theme.spacing(3),
  },
}))

export default () => {
  const classes = useStyles()
  // load from store
  const user = useStore(state => state.user)
  const organizers = useStore(state => state.organizers)
  const places = useStore(state => state.places)
  const createOrganizer = useStore(state => state.createOrganizer)
  const deleteOrganizer = useStore(state => state.deleteOrganizer)
  const updateOrganizer = useStore(state => state.updateOrganizer)

  const { breakpoints, spacing } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const history = useHistory()
  const { id } = useParams()
  const isNew = id === 'new'
  const isAdmin = user.role === ROLE.ADMIN
  const [readOnly, setReadOnly] = useState(!isNew)
  const [saving, setSaving] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)

  //////////////////////////////////////////////////
  // state
  const organizer = isNew
    ? null
    : organizers.find(p => p.id === parseInt(id, 10))
  // state
  const [state, dispatch] = createReducer(organizer)
  const handleChange = (type, payload) => {
    dispatch({ type, payload })
  }

  const canSave = () => {
    let ok = true
    const keysToCheck = [
      'lastName',
      'firstName',
      'email',
      'organizerName',
      'organizerEmail',
      'organizerImage',
    ]
    if (state.fixedPlace) {
      keysToCheck.push('places')
    }
    keysToCheck.forEach(key => {
      // latitude, longitude
      if (state[key] === null) ok = false
      // others
      else if (state[key].length === 0) ok = false
    })
    if (state.password !== '') {
      const shouldBeLongEnough = state.password.length >= 8
      const shouldMatch = state.password === state.passwordConfirm
      if (!shouldBeLongEnough || !shouldMatch) ok = false
    }
    return ok
  }

  const handleSave = async () => {
    setSaving(true)
    setReadOnly(true)
    if (isNew) {
      const id = await createOrganizer(state)
      history.push(`/organizers/${id}`)
    } else {
      await updateOrganizer({ ...state, id })
    }
    setSaving(false)
  }

  const handleDelete = async () => {
    setConfirmDelete(false)
    setDeleting(true)
    await deleteOrganizer(id)
    history.push('/organizers')
  }

  // useEffect(() => {
  //   console.log(state)
  // }, [state])

  return (
    <Paper style={{ height: '100%' }}>
      <Box component="form" p={2}>
        {/* organizerName */}
        <DetailTitle title={state.organizerName} />
        <Box className={classes.inputGroup}>
          {/* lastName */}
          <TextField
            className={classes.userInput}
            label="Nom d'utilisateur"
            value={state.lastName}
            readOnly={readOnly}
            onChange={value => handleChange('lastName', value)}
          />
          {/* firstName */}
          <TextField
            className={classes.userInput}
            label="Prénom d'utilisateur"
            value={state.firstName}
            readOnly={readOnly}
            onChange={value => handleChange('firstName', value)}
          />
        </Box>
        {/* email */}
        <TextField
          label="Email de connexion"
          value={state.email}
          readOnly={readOnly}
          onChange={value => handleChange('email', value)}
        />
        {/* password */}
        {!readOnly && (
          <Box className={classes.inputGroup}>
            <TextField
              className={classes.userInput}
              label="Nouveau mot de passe (vide pour le laisser inchangé)"
              type="password"
              value={state.password}
              readOnly={readOnly}
              onChange={value => handleChange('password', value)}
            />
            <TextField
              className={classes.userInput}
              label="Confirmation du mot de passe"
              type="password"
              value={state.passwordConfirm}
              readOnly={readOnly}
              onChange={value => handleChange('passwordConfirm', value)}
            />
          </Box>
        )}
        {/* fixedPlace */}
        {isAdmin && (
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={state.role === ROLE.ADMIN}
                disabled={readOnly}
                onChange={e => handleChange('role', e.target.checked)}
                color="secondary"
                value="role"
              />
            }
            label="Administrateur"
          />
        )}
        {/* organizerName */}
        <TextField
          label="Nom de l'organisateur"
          value={state.organizerName}
          readOnly={readOnly}
          onChange={value => handleChange('organizerName', value)}
        />
        {/* organizerEmail */}
        <TextField
          label="Email de l'organisateur"
          value={state.organizerEmail}
          readOnly={readOnly}
          onChange={value => handleChange('organizerEmail', value)}
        />
        {/* organizerUrl */}
        <TextField
          label="Site web de l'organisateur"
          value={state.organizerUrl ?? ''}
          readOnly={readOnly}
          onChange={value => handleChange('organizerUrl', value)}
        />
        {/* organizerPhone */}
        <TextField
          label="Téléphone de l'organisateur"
          value={state.organizerPhone}
          readOnly={readOnly}
          onChange={value => handleChange('organizerPhone', value)}
        />
        {/* organizerImage */}
        <Dropzone
          label="Image de l'organisateur"
          value={state.organizerImage}
          readOnly={readOnly}
          onChange={file => handleChange('organizerImage', file)}
        />
        {/* fixedPlace */}
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch
              checked={state.fixedPlace}
              disabled={readOnly}
              onChange={e => handleChange('fixedPlace', e.target.checked)}
              color="secondary"
              value="fixedPlace"
            />
          }
          label="Lieu fixe"
        />
        {/* onApp */}
        {state.fixedPlace && isAdmin && (
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={state.onApp}
                disabled={readOnly}
                onChange={e => handleChange('onApp', e.target.checked)}
                color="secondary"
                value="onApp"
              />
            }
            label="Afficher dans l'app"
          />
        )}
        {/* organizerAddress */}
        {state.fixedPlace && (
          <Places
            associatedPlaces={places.filter(p => p.id === state.places[0])}
            availablePlaces={places}
            singlePlace
            readOnly={readOnly}
            addPlaceTitle="Sélectionner une adresse pour cet organisateur"
            newPlaceTitle="Ajouter une adresse pour cet organisateur"
            onRemove={() => handleChange('place', [])}
            onAdd={placesId => handleChange('place', [placesId[0]])}
            onNew={placeId => handleChange('place', [placeId])}
          />
        )}
        {/* openings */}
        {state.fixedPlace && (
          <>
            <TextField
              label="Infos pratiques"
              value={state.openingsDescription ?? ''}
              readOnly={readOnly}
              onChange={value => handleChange('openingsDescription', value)}
            />
            <Openings
              openings={state.openings}
              readOnly={readOnly}
              onUpdate={e => handleChange('openings.update', e)}
              onRemove={index => handleChange('openings.remove', index)}
              onNew={e => handleChange('openings.new')}
            />
          </>
        )}
      </Box>
      {(isAdmin || user.id === parseInt(id, 10)) && (
        <Box
          position="fixed"
          top={largeScreen ? '96px' : '88px'}
          right={largeScreen ? '34px' : '10px'}
        >
          {readOnly && (
            <Fab color="primary" onClick={e => setReadOnly(false)}>
              <Edit />
            </Fab>
          )}
          {!readOnly && (
            <Fab color="primary" onClick={handleSave} disabled={!canSave()}>
              <Save />
            </Fab>
          )}
          {isAdmin && !isNew && user.id !== parseInt(id, 10) && (
            <Fab
              color="secondary"
              onClick={e => setConfirmDelete(true)}
              style={{ marginLeft: spacing(1) }}
            >
              <Delete />
            </Fab>
          )}
        </Box>
      )}
      {saving && (
        <SavingBox
          text={`${isNew ? 'Création' : 'Mise à jour'} de l'organisateur...`}
        />
      )}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Confirmer la suppression ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Supprimer cet organisateur entrainera sa suppression dans les
            manifestations associées.
          </DialogContentText>
          <DialogContentText>
            Si il est l'unique organisateur d'une manifestation, celle-ci sera
            alors sans organisateur.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      {deleting && <SavingBox text="Suppression de l'organisateur..." />}
    </Paper>
  )
}
