import React, { useState, useEffect, useMemo } from 'react'
import { Box, TextField, ListItemText } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import useScript from '../../hooks/useScript'

const GMAPS_API_URL = `https://maps.googleapis.com/maps/api/js?libraries=places&key=`

export default ({
  initialValue,
  readOnly,
  gmapsApiAccessToken,
  queryParams,
  onSelected,
}) => {
  const [loaded, error] = useScript(`${GMAPS_API_URL}${gmapsApiAccessToken}`)

  const autoComplete = useMemo(() => {
    if (loaded && !error) {
      const sessionToken = new window.google.maps.places.AutocompleteSessionToken()
      const bounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(
          queryParams.bounds[0].lat,
          queryParams.bounds[0].lon
        ),
        new window.google.maps.LatLng(
          queryParams.bounds[1].lat,
          queryParams.bounds[1].lon
        )
      )
      const componentRestrictions = { country: queryParams.country }
      const autoCompleteService = new window.google.maps.places.AutocompleteService()
      return (input, cb) => {
        autoCompleteService.getPlacePredictions(
          { input, componentRestrictions, bounds, sessionToken },
          cb
        )
      }
    } else return null
  }, [loaded, error, queryParams.bounds, queryParams.country])

  const geocoderService = useMemo(() => {
    if (loaded && !error) return new window.google.maps.Geocoder()
    else return null
  }, [loaded, error])

  // state
  const [value, setValue] = useState(initialValue)
  const [results, setResults] = useState([])

  // retrigger geocoder on value changes
  useEffect(
    () => {
      if (value === '') {
        if (results.length > 0) {
          setResults([])
          onSelected(null)
        }
      } else {
        if (loaded) {
          autoComplete(value, (predictions, status) => {
            if (status === 'OK') setResults(predictions)
            else setResults([])
          })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  )

  const handleChange = item => {
    if (item) {
      geocoderService.geocode(
        { address: item.description },
        (results, status) => {
          if (status === 'OK' && results?.length) {
            const result = {
              address: results[0].formatted_address.split(', France')[0],
              longitude: results[0].geometry.location.lng(),
              latitude: results[0].geometry.location.lat(),
            }
            setValue(result.address)
            onSelected(result)
          }
        }
      )
    }
  }

  return (
    <Box position="absolute" p={1} style={{ zIndex: 1, width: '100%' }}>
      {readOnly ? (
        <TextField
          fullWidth
          color="secondary"
          variant="outlined"
          value={value}
          inputProps={{
            readOnly: true,
            disabled: true,
            style: {
              paddingLeft: '12px',
            },
          }}
          margin="dense"
          style={{
            position: 'relative',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            marginTop: 0,
          }}
        />
      ) : (
        <Autocomplete
          options={results}
          getOptionLabel={result => result?.formatted_address ?? ''}
          filterOptions={x => x}
          freeSolo
          disableOpenOnFocus
          inputValue={value}
          onInputChange={e => e && setValue(e.target?.value ?? '')}
          onChange={(e, item) => handleChange(item)}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              color="secondary"
              variant="outlined"
              placeholder="Entrer l'adresse"
              value={value}
              margin="dense"
              style={{
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                marginTop: 0,
              }}
            />
          )}
          renderOption={result => <ListItemText primary={result.description} />}
        />
      )}
    </Box>
  )
}
