import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Table from '../Table'

export default ({
  title,
  open,
  singlePlace,
  availablePlaces,
  onClose,
  onAdded,
}) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const [additionalPlaces, setAdditionalPlaces] = useState([])

  const handleSelectionChange = rows => {
    if (singlePlace) {
      const row = rows.find(r => !additionalPlaces.includes(r.id))
      if (row) setAdditionalPlaces([row.id])
      else setAdditionalPlaces([])
    } else setAdditionalPlaces(rows.map(r => r.id))
  }

  const handleClose = () => {
    setAdditionalPlaces([])
    onClose()
  }

  const handleSubmit = () => {
    onAdded(additionalPlaces)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={props => (
        <Paper {...props} style={{ width: largeScreen ? '60%' : '100%' }} />
      )}
      maxWidth="md"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Table
          columns={[
            { title: 'Nom', field: 'name' },
            { title: 'Adresse', field: 'address' },
          ]}
          data={availablePlaces.map((p, i) => ({
            id: p.id,
            name: p.name,
            address: p.address,
            tableData: {
              checked: additionalPlaces.includes(p.id),
            },
          }))}
          options={{
            selection: true,
            pageSize: 10,
            pageSizeOptions: [],
            emptyRowsWhenPaging: true,
            padding: 'dense',
            showSelectAllCheckbox: !singlePlace,
          }}
          onSelectionChange={handleSelectionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Annuler
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          disabled={additionalPlaces.length === 0}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}
