import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs, useMediaQuery, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Room } from '@material-ui/icons'
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl'

import Table from '../../components/Table'
import useStore from '../../store'
import NewItem from '../../components/NewItem'

export default () => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const history = useHistory()

  // load from store
  const places = useStore(state => state.places)

  // handle columns display according to the screen size
  const columns = []
  if (largeScreen) columns.push({ title: '#', field: 'id' })
  columns.push({ title: 'Nom', field: 'name' })
  columns.push({ title: 'Adresse', field: 'address' })

  // display options
  const options = {
    padding: largeScreen ? 'default' : 'dense',
  }

  // handle row click
  const handleRowClick = (event, rowData) => {
    history.push(`/places/${rowData.id}`)
  }

  // map
  const initialViewport = {
    latitude: 47.2173,
    longitude: -1.5534,
    zoom: 8,
  }
  const [viewport, setViewport] = useState(initialViewport)

  const handleMarkerClick = placeId => {
    history.push(`/places/${placeId}`)
  }

  // tabs
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary">
        <Tab label="Liste" />
        <Tab label="Carte" />
      </Tabs>
      {value === 0 && (
        <Table
          columns={columns}
          data={places}
          options={options}
          onRowClick={handleRowClick}
        />
      )}
      {value === 1 && (
        <ReactMapGL
          height="100%"
          width="100%"
          {...viewport}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          onViewportChange={setViewport}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          {places.map((p, i) => (
            <Marker
              key={i}
              latitude={p.latitude}
              longitude={p.longitude}
              offsetLeft={-20}
              offsetTop={-20}
            >
              <Room
                color="secondary"
                style={{ fontSize: 40, cursor: 'pointer' }}
                onClick={e => handleMarkerClick(p.id)}
              />
            </Marker>
          ))}
          <Box
            position="absolute"
            pr={1}
            pb={largeScreen ? 3.5 : 5.5}
            style={{ bottom: 0, right: 0 }}
          >
            <NavigationControl showCompass={false} />
          </Box>
        </ReactMapGL>
      )}
      <NewItem item="place" />
    </>
  )
}
