import React, { useReducer, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Select,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { DatePicker, TimePicker } from '@material-ui/pickers'

import { constants } from '@readymade/shared'
import TextField from '../TextField'
import Input from '../Input'
import { format, set } from 'date-fns'
import useStore from '../../store'
import Places from '../Places'

const {
  event: { TYPE },
} = constants

const DialogView = ({ event, initialAddress, onSubmit, onClose }) => {
  const { breakpoints, spacing } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))

  const places = useStore(state => state.places)

  const initialState = {
    title: '',
    type: TYPE.VERNISSAGE,
    date: new Date(),
    opening: '18:00',
    placeId: initialAddress,
  }
  const [state, dispatch] = useReducer(
    (state, { type, payload }) => {
      switch (type) {
        case 'title':
          return { ...state, title: payload.title }
        case 'type':
          return { ...state, type: payload.type }
        case 'date':
          return { ...state, date: payload.date }
        case 'opening':
          return { ...state, opening: format(payload.opening, 'HH:mm') }
        case 'place':
          return { ...state, placeId: payload.placeId }
        case 'reset':
          return initialState
        default:
          break
      }
    },
    event ? event : initialState
  )

  const handleClose = () => {
    dispatch({ type: 'reset' })
    onClose()
  }

  const handleSubmit = () => {
    onSubmit(state)
  }

  const submitDisabled = () => {
    const noTitle = state.title === ''
    const noPlace = typeof state.placeId === 'undefined'
    return noTitle || noPlace
  }

  const now = new Date()
  const [hours, minutes] = state.opening.split(':')
  const hour = set(now, {
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  })

  return (
    <>
      <DialogTitle>
        {event ? "Modifier l'" : 'Ajouter un '}évènement pour cette
        manifestation
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box display="flex" flexDirection={largeScreen ? 'row' : 'column'}>
            <Input
              label="Type de l'évènement"
              mt={2}
              mr={largeScreen ? 4 : 0}
              mb={largeScreen ? 0 : 4}
            >
              <Select
                color="secondary"
                style={{
                  marginRight: spacing(2),
                  width: '120px',
                }}
                value={state.type}
                onChange={e =>
                  dispatch({ type: 'type', payload: { type: e.target.value } })
                }
              >
                {Object.values(TYPE).map((t, i) => (
                  <MenuItem value={t} key={i}>
                    {t}
                  </MenuItem>
                ))}
              </Select>
            </Input>
            <Box flex={1}>
              <TextField
                label="Description de l'évènement"
                value={state.title}
                mt={2}
                fullWidth
                style={{ flex: 1 }}
                onChange={e =>
                  dispatch({ type: 'title', payload: { title: e } })
                }
              />
            </Box>
          </Box>
          <Input label="Prévisualisation du titre de l'évènement">
            <Typography>{`[${state.type}] ${state.title}`}</Typography>
          </Input>
        </Box>
        <Box display="flex">
          <Input label="Date de l'évènement">
            <DatePicker
              color="secondary"
              value={state.date}
              format="d MMM yyyy"
              variant="inline"
              style={{ width: '120px' }}
              onChange={e => dispatch({ type: 'date', payload: { date: e } })}
              animateYearScrolling
            />
          </Input>
          <Input label="Horaire de l'évènement" ml={2}>
            <TimePicker
              color="secondary"
              variant="inline"
              ampm={false}
              style={{ width: '120px' }}
              value={hour}
              onChange={e =>
                dispatch({ type: 'opening', payload: { opening: e } })
              }
            />
          </Input>
        </Box>
        <Places
          associatedPlaces={places.filter(p => p.id === state.placeId)}
          availablePlaces={places}
          singlePlace
          readOnly={false}
          addPlaceTitle="Sélectionner une adresse pour cet évènement"
          newPlaceTitle="Ajouter une adresse pour cet évènement"
          onRemove={() =>
            dispatch({ type: 'place', payload: { placeId: undefined } })
          }
          onAdd={placesId =>
            dispatch({ type: 'place', payload: { placeId: placesId[0] } })
          }
          onNew={placeId =>
            dispatch({ type: 'place', payload: { placeId: placeId } })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Annuler
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          disabled={submitDisabled()}
        >
          Valider
        </Button>
      </DialogActions>
    </>
  )
}

export default ({ open, event, initialAddress, onClose, onCreated }) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const [saving, setSaving] = useState(false)
  const createEvent = useStore(state => state.createEvent)
  const updateEvent = useStore(state => state.updateEvent)

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = async newEvent => {
    setSaving(true)
    if (event) {
      // update event
      await updateEvent(newEvent)
    } else {
      // create event
      const id = await createEvent(newEvent)
      onCreated(id)
    }
    handleClose()
    setSaving(false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={props => (
        <Paper {...props} style={{ width: largeScreen ? '60%' : '100%' }} />
      )}
      maxWidth="md"
    >
      {saving && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={11}
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
        >
          <Typography>
            {event ? 'Mise à jour ' : 'Création '}de l'évènement...
          </Typography>
        </Box>
      )}
      <DialogView
        event={event}
        initialAddress={initialAddress}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </Dialog>
  )
}
