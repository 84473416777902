import React from 'react'
import { Paper } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { format, set } from 'date-fns'
import { TimePicker } from '@material-ui/pickers'
import Input from '../Input'

export default ({ openings, readOnly, onChange }) => {
  const { spacing } = useTheme()
  const opening = openings[0]
  const now = new Date()
  const [startHour, startMinute] = opening.start.split(':')
  const start = set(now, {
    hours: startHour,
    minutes: startMinute,
    seconds: 0,
    milliseconds: 0,
  })
  return (
    <Input label="Horaire">
      <Paper style={{ padding: spacing(1) }}>
        <TimePicker
          color="secondary"
          variant="inline"
          ampm={false}
          style={{ width: '120px', marginRight: spacing(2) }}
          value={start}
          readOnly={readOnly}
          onChange={e => {
            const value = format(e, 'HH:mm')
            onChange(value)
          }}
        />
      </Paper>
    </Input>
  )
}
