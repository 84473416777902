import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Fab,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Edit, Save, Delete } from '@material-ui/icons'
import { useParams } from 'react-router-dom'
import { constants } from '@readymade/shared'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

const {
  manifestation: { TYPE, STATUS },
} = constants

export default ({ state, readOnly, onEdit, onSave, onDelete, onUpdate }) => {
  const { breakpoints, spacing } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const { id } = useParams()
  const isNew = id === 'new'
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmSave, setConfirmSave] = useState(false)
  const [status, setStatus] = useState(STATUS.PROGRAMMEE)

  // Si exposition : tous les champs sauf l’agenda
  // Si Festival : idem
  // Si performance/conf : tous les champs obligatoires
  // Si Pérenne : Tous sauf agenda

  const canSave = () => {
    let ok = true
    const keysToCheck = [
      'title',
      'organizers',
      'artists',
      'description',
      'image',
      'places',
    ]
    switch (state.type) {
      case TYPE.EXPOSITION:
      case TYPE.FESTIVAL_COLLOQUE:
        keysToCheck.push('start', 'end')
        break
      case TYPE.PERFORMANCE_CONFERENCE:
        keysToCheck.push('start', 'openings')
        break
      default:
        break
    }
    keysToCheck.forEach(key => {
      // null values
      if (state[key] === null) ok = false
      // others
      else if (state[key].length === 0) ok = false
    })
    return ok
  }

  const handleSave = () => {
    onSave(status)
    setConfirmSave(false)
  }

  const handleDelete = () => {
    onDelete()
    setConfirmDelete(false)
  }

  return (
    <Box
      position="fixed"
      top={largeScreen ? '96px' : '88px'}
      right={largeScreen ? '34px' : '10px'}
      style={{ zIndex: 2 }}
    >
      {readOnly && (
        <Fab color="primary" onClick={onEdit}>
          <Edit />
        </Fab>
      )}
      {!readOnly && (
        <Fab
          color="primary"
          onClick={() => (isNew ? setConfirmSave(true) : onUpdate())}
          disabled={!canSave()}
        >
          <Save />
        </Fab>
      )}
      {!isNew && (
        <Fab
          color="secondary"
          onClick={() => setConfirmDelete(true)}
          style={{ marginLeft: spacing(1) }}
        >
          <Delete />
        </Fab>
      )}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Confirmer la suppression ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Supprimer cette manifestation supprimera aussi les évènements
            assosicés.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Confirmer l'ajout ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous allez {isNew ? 'publier' : 'mettre à jour'} une manifestation
            culturelle de type:{' '}
            <span style={{ fontWeight: 'bold' }}>{state.type}</span>
            <br />
            Son titre est :{' '}
            <span style={{ fontWeight: 'bold' }}>{state.title}</span>
            {state.type !== TYPE.PERENNE && (
              <>
                <br />
                Elle {state.end ? 'se déroulera du' : 'aura lieu le'}{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {format(state.start, 'd MMMM yyyy', { locale: fr })}
                </span>
                {state.end && (
                  <>
                    {' '}
                    au{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {format(state.end, 'd MMMM yyyy', { locale: fr })}
                    </span>
                  </>
                )}
              </>
            )}
          </DialogContentText>
          {state.type !== TYPE.PERENNE && (
            <>
              <Typography variant="h6">Confirmez-vous ?</Typography>
              <RadioGroup
                value={status}
                onChange={e => setStatus(e.target.value)}
                row={false}
              >
                <FormControlLabel
                  value={STATUS.PROGRAMMEE}
                  control={<Radio />}
                  label="Je confirme ces dates (manifestation programmée)"
                />
                <FormControlLabel
                  value={STATUS.A_CONFIRMER}
                  control={<Radio />}
                  label="Je confirmerais ces dates plus tard (dates à confirmer)"
                />
                <FormControlLabel
                  value={STATUS.NON_PROGRAMMEE}
                  control={<Radio />}
                  label="Je ne programme pas cette manifestation pour le moment (brouillon)"
                />
              </RadioGroup>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmSave(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="secondary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
