import React from 'react'
import {
  Paper,
  Box,
  Select,
  MenuItem,
  IconButton,
  Button,
} from '@material-ui/core'
import { Add, ArrowRightAlt, Delete } from '@material-ui/icons'
import { useTheme } from '@material-ui/core/styles'
import { format, set } from 'date-fns'
import BoxInput from '../BoxInput'
import { TimePicker } from '@material-ui/pickers'

export default ({ openings, readOnly, onUpdate, onRemove, onNew }) => {
  const { spacing } = useTheme()
  const handleUpdate = (index, dayOfWeek, start, end) => {
    const formattedStart = format(start, 'HH:mm')
    const formattedEnd = format(end, 'HH:mm')
    onUpdate({
      index,
      opening: { dayOfWeek, start: formattedStart, end: formattedEnd },
    })
  }
  return (
    <BoxInput label="Horaires d'ouverture">
      <Paper style={{ padding: spacing(1) }}>
        {openings.map((o, i) => {
          const now = new Date()
          const [startHour, startMinute] = o.start.split(':')
          const start = set(now, {
            hours: startHour,
            minutes: startMinute,
            seconds: 0,
            milliseconds: 0,
          })
          const [endHour, endMinute] = o?.end?.split(':') ?? o.start.split(':')
          const end = set(now, {
            hours: endHour,
            minutes: endMinute,
            seconds: 0,
            milliseconds: 0,
          })
          return (
            <Box display="flex" alignItems="center" mt={i ? 2 : 0} key={i}>
              <Select
                color="secondary"
                style={{
                  marginLeft: spacing(1),
                  marginRight: spacing(2),
                  width: '120px',
                }}
                value={o?.dayOfWeek ?? 0}
                onChange={e => {
                  handleUpdate(i, e.target.value, start, end)
                }}
                readOnly={readOnly}
              >
                <MenuItem value={0}>Lundi</MenuItem>
                <MenuItem value={1}>Mardi</MenuItem>
                <MenuItem value={2}>Mercredi</MenuItem>
                <MenuItem value={3}>Jeudi</MenuItem>
                <MenuItem value={4}>Vendredi</MenuItem>
                <MenuItem value={5}>Samedi</MenuItem>
                <MenuItem value={6}>Dimanche</MenuItem>
              </Select>
              <TimePicker
                color="secondary"
                variant="inline"
                ampm={false}
                style={{ width: '120px', marginRight: spacing(2) }}
                value={start}
                maxDate={end}
                readOnly={readOnly}
                onChange={e => {
                  handleUpdate(i, o.dayOfWeek, e, end)
                }}
              />
              <ArrowRightAlt style={{ marginRight: spacing(2) }} />
              <TimePicker
                color="secondary"
                variant="inline"
                ampm={false}
                style={{ width: '120px' }}
                value={end}
                minDate={start}
                readOnly={readOnly}
                onChange={e => {
                  handleUpdate(i, o.dayOfWeek, start, e)
                }}
              />
              {!readOnly && (
                <IconButton
                  onClick={e => onRemove(i)}
                  style={{ marginLeft: 'auto' }}
                >
                  <Delete />
                </IconButton>
              )}
            </Box>
          )
        })}
      </Paper>
      {!readOnly && (
        <Box mt={1}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={e => onNew()}
          >
            Ajouter un horaire
          </Button>
        </Box>
      )}
    </BoxInput>
  )
}
