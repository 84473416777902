import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { PhoneAndroid } from '@material-ui/icons'
import missing from '../../assets/images/missing.png'
import { useHistory } from 'react-router-dom'
import useStore from '../../store'
import { constants } from '@readymade/shared'

const {
  organizer: { ROLE },
} = constants

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  media: {
    height: 200,
    backgroundSize: 'cover',
    backgroundColor: theme.palette.grey[100],
  },
  cardContent: {
    position: 'relative',
  },
  onApp: {
    position: 'absolute',
    right: '16px',
    color: 'green',
  },
}))

export default ({ organizer }) => {
  const classes = useStyles()
  const history = useHistory()

  const user = useStore(state => state.user)
  const isAdmin = user.role === ROLE.ADMIN

  const handleCardClick = () => {
    history.push(`/organizers/${organizer.id}`)
  }

  const image = organizer.organizerImage
    ? `${process.env.REACT_APP_SERVER_URL}${organizer.organizerImage}`
    : missing

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleCardClick}>
        <CardMedia className={classes.media} image={image} title="missing" />
        <CardContent className={classes.cardContent}>
          {isAdmin && organizer.onApp && (
            <PhoneAndroid className={classes.onApp} />
          )}
          <Typography gutterBottom variant="h5" component="h2">
            {organizer.organizerName}
          </Typography>
          {organizer.organizerAddress && (
            <Typography variant="body2" color="textSecondary" component="p">
              {organizer.organizerAddress}
            </Typography>
          )}
          {organizer.organizerEmail && (
            <Typography variant="body2" color="textSecondary" component="p">
              {organizer.organizerEmail}
            </Typography>
          )}
          {organizer.organizerPhone && (
            <Typography variant="body2" color="textSecondary" component="p">
              {organizer.organizerPhone}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
