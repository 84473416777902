import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import frLocale from 'date-fns/locale/fr'
import { format } from 'date-fns'

import theme from '../../theme'
import AppWrapper from '../AppWrapper'
import Login from '../../views/Login'
import Manifestations from '../../views/Manifestations'
import Manifestation from '../../views/Manifestation'
import Organizers from '../../views/Organizers'
import Organizer from '../../views/Organizer'
import Places from '../../views/Places'
import Place from '../../views/Place'
import Home from '../../views/Home'
import Promo from '../../views/Promo'
import useStore from '../../store'
import { Box, Typography } from '@material-ui/core'

class FrLocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, 'd MMM yyyy', { locale: frLocale })
  }
}

const PrivateRoute = ({ children, ...rest }) => {
  const user = useStore(state => state.user)
  const token = localStorage.getItem('token')
  return (
    <Route
      {...rest}
      render={() =>
        token && user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  )
}

export default () => {
  const loading = useStore(state => state.loading)

  return (
    <MuiPickersUtilsProvider utils={FrLocalizedUtils} locale={frLocale}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <AppWrapper>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Typography>LOADING...</Typography>
                </Box>
              ) : (
                <>
                  <PrivateRoute exact path="/">
                    <Home />
                  </PrivateRoute>
                  <PrivateRoute exact path="/manifestations">
                    <Manifestations />
                  </PrivateRoute>
                  <PrivateRoute path="/manifestations/:id">
                    <Manifestation />
                  </PrivateRoute>
                  <PrivateRoute exact path="/places">
                    <Places />
                  </PrivateRoute>
                  <PrivateRoute exact path="/places/:id">
                    <Place />
                  </PrivateRoute>
                  <PrivateRoute exact path="/organizers">
                    <Organizers />
                  </PrivateRoute>
                  <PrivateRoute exact path="/organizers/:id">
                    <Organizer />
                  </PrivateRoute>
                  <PrivateRoute exact path="/promo">
                    <Promo />
                  </PrivateRoute>
                </>
              )}
            </AppWrapper>
          </Switch>
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}
