import React, { useState } from 'react'
import {
  TableContainer,
  Paper,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Button,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Delete, Search, Launch } from '@material-ui/icons'
import Input from '../Input'
import AddOrganizerDialog from './AddOrganizerDialog'
import useStore from '../../store'
import { useHistory } from 'react-router-dom'

export default ({
  associatedOrganizers,
  availableOrganizers,
  isAdmin,
  readOnly,
  onRemove,
  onAdd,
}) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const history = useHistory()

  const user = useStore(state => state.user)

  const [addModal, setAddModal] = useState(false)

  const handleAdd = additionalOrganizers => {
    onAdd(additionalOrganizers)
  }

  return (
    <Input label="Organisateurs">
      <TableContainer component={Paper}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell colSpan={2}>Téléphone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {associatedOrganizers.map((o, i) => {
              const disabled = o.id === user.id && !isAdmin
              const Wrapper = ({ children }) => {
                return disabled ? (
                  <Tooltip title="Vous ne pouvez pas vous supprimer">
                    <span>{children}</span>
                  </Tooltip>
                ) : (
                  <>{children}</>
                )
              }
              return (
                <TableRow
                  key={i}
                  style={{ height: readOnly ? '61px' : 'inherit' }}
                >
                  <TableCell>
                    {o.organizerName}
                    {largeScreen && (
                      <IconButton
                        onClick={e => history.push(`/organizers/${o.id}`)}
                        size="small"
                        style={{ paddingBottom: '4px' }}
                      >
                        <Launch style={{ width: '0.6em', height: '0.6em' }} />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>{o.organizerEmail}</TableCell>
                  <TableCell>{o.organizerPhone}</TableCell>
                  {!readOnly && (
                    <TableCell align="right">
                      <Wrapper>
                        <IconButton
                          onClick={e => onRemove(i)}
                          disabled={disabled}
                          style={{ marginLeft: 'auto' }}
                        >
                          <Delete />
                        </IconButton>
                      </Wrapper>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!readOnly && (
        <Box
          mt={1}
          display="flex"
          flexDirection={largeScreen ? 'row' : 'column'}
          alignItems="flex-start"
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Search />}
            onClick={e => setAddModal(true)}
          >
            Ajouter un organisateur
          </Button>
        </Box>
      )}
      <AddOrganizerDialog
        open={addModal}
        availableOrganizers={availableOrganizers}
        onClose={() => setAddModal(false)}
        onAdded={handleAdd}
      />
    </Input>
  )
}
