export const manifestation = {
  TYPE: {
    EXPOSITION: 'Exposition',
    FESTIVAL_COLLOQUE: 'Festival/Colloque',
    PERFORMANCE_CONFERENCE: 'Performance/Conférence',
    PERENNE: "Pérenne/Point d'intérêt",
  },
  STATUS: {
    PROGRAMMEE: 'Programmée',
    NON_PROGRAMMEE: 'Non programmée',
    A_CONFIRMER: 'À confirmer',
  },
}
