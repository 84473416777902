import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CloudUpload, Delete } from '@material-ui/icons'
import { useDropzone } from 'react-dropzone'
import { Paper, Box, Typography, Fab } from '@material-ui/core'
import Input from '../Input'

const useStyles = makeStyles(theme => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: theme.palette.grey[500],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[50],
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  thumbContainer: {
    width: '100%',
    height: '200px',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: '460px',
    },
  },
  thumb: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  thumbDelete: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      right: '-32px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
}))

export default ({ label, value, readOnly, onChange }) => {
  const classes = useStyles()
  const [preview, setPreview] = useState()

  useEffect(() => {
    const getBlob = async () => {
      let preview
      // when fetched from the backend we only have the url of the file
      // not the file itself, so we get it from fetch/blob
      if (typeof value === 'string') {
        const url = `${process.env.REACT_APP_SERVER_URL}${value}`
        const req = await fetch(url)
        const blob = await req.blob()
        const fileName = value.split('/')[1]
        const file = new File([blob], fileName)
        // create the preview from the file
        preview = URL.createObjectURL(file)
        // notify we have a file ready to upload
        onChange(file)
      } else {
        // if not a string, it should already be a file instance
        // we can create the preview from it
        preview = URL.createObjectURL(value)
      }
      setPreview(preview)
    }
    if (value) getBlob()
  }, [value, onChange])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    disabled: readOnly,
    onDrop(acceptedFiles) {
      const file = acceptedFiles[0]
      if (file) {
        const preview = URL.createObjectURL(file)
        setPreview(preview)
        onChange(file)
      }
    },
  })

  const handleDelete = () => {
    setPreview(null)
    onChange(null)
  }

  function generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime() //Timestamp
    var d2 =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16
        if (d > 0) {
          r = (d + r) % 16 | 0
          d = Math.floor(d / 16)
        } else {
          r = (d2 + r) % 16 | 0
          d2 = Math.floor(d2 / 16)
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
      }
    )
  }

  return (
    <Input label={label}>
      <span style={{ fontStyle: 'italic' }}>
        Cliquez sur l'image pour la télécharger
      </span>
      {!readOnly && (
        <Box className={classes.dropzone} {...getRootProps()}>
          <input {...getInputProps()} />
          <CloudUpload style={{ fontSize: 40 }} />
          <Typography variant="body1">Déposer un fichier ici...</Typography>
          <Typography variant="body1">Taille max: 3MB</Typography>
        </Box>
      )}
      {preview && (
        <Paper className={classes.thumbContainer}>
          <a
            href={preview}
            download={`${generateUUID()}.png`}
            className={classes.thumb}
            // style={{ backgroundImage: `url(${preview})` }}
          >
            <img
              src={`${preview}`}
              loading="lazy"
              alt=""
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
            {!readOnly && (
              <Fab
                color="secondary"
                className={classes.thumbDelete}
                onClick={handleDelete}
              >
                <Delete />
              </Fab>
            )}
          </a>
        </Paper>
      )}
    </Input>
  )
}
