import React from 'react'
import Input from '../Input'
import { TextField } from '@material-ui/core'

export default ({
  label,
  value,
  multiline,
  mt,
  readOnly,
  onChange,
  ...textFieldProps
}) => (
  <Input label={label} mt={mt}>
    <TextField
      required
      fullWidth
      color="secondary"
      multiline={multiline}
      value={value}
      {...textFieldProps}
      inputProps={{
        readOnly: readOnly,
        disabled: readOnly,
      }}
      onChange={e => onChange(e.target.value)}
    />
  </Input>
)
