import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Paper,
  Box,
  useMediaQuery,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Edit, Save, Delete } from '@material-ui/icons'

import { constants } from '@readymade/shared'
import DetailTitle from '../../components/DetailTitle'
import TextField from '../../components/TextField'
import GeocoderMap from '../../components/GeocoderMap'
import SavingBox from '../../components/SavingBox'

import createReducer from './reducer'
import useStore from '../../store'

const {
  organizer: { ROLE },
} = constants

export default () => {
  // load from store
  const user = useStore(state => state.user)
  const places = useStore(state => state.places)
  const createPlace = useStore(state => state.createPlace)
  const deletePlace = useStore(state => state.deletePlace)
  const updatePlace = useStore(state => state.updatePlace)
  const fetchAll = useStore(state => state.fetchAll)

  const { breakpoints, spacing } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const history = useHistory()
  const { id } = useParams()
  const isNew = id === 'new'
  const isAdmin = user.role === ROLE.ADMIN
  const [readOnly, setReadOnly] = useState(!isNew)
  const [saving, setSaving] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)

  //////////////////////////////////////////////////
  // state
  const place = isNew ? null : places.find(p => p.id === parseInt(id, 10))
  // state
  const [state, dispatch] = createReducer(place)
  const handleChange = (type, payload) => {
    dispatch({ type, payload })
  }

  const canSave = () => {
    return (
      state.name.length > 0 &&
      state.address.length > 0 &&
      state.longitude &&
      state.latitude
    )
  }

  const handleSave = async () => {
    setSaving(true)
    setReadOnly(true)
    if (isNew) {
      const id = await createPlace(state)
      history.push(`/places/${id}`)
    } else {
      await updatePlace(state.id ? state : { ...state, id })
    }
    setSaving(false)
  }

  const handleDelete = async () => {
    setConfirmDelete(false)
    setDeleting(true)
    await deletePlace(id)
    await fetchAll()
    history.push('/places')
  }

  // useEffect(() => {
  //   console.log(state)
  // }, [state])

  return (
    <Paper style={{ height: '100%' }}>
      <Box component="form" p={2}>
        {/* name */}
        <DetailTitle title={state.name} />
        {/* name */}
        <TextField
          label="Nom du lieu"
          value={state.name}
          readOnly={readOnly}
          onChange={value => handleChange('name', value)}
        />
        {/* address */}
        <GeocoderMap
          place={place}
          readOnly={readOnly}
          onChange={address => handleChange('address', address)}
        />
      </Box>
      {(isAdmin || isNew) && (
        <Box
          position="fixed"
          top={largeScreen ? '96px' : '88px'}
          right={largeScreen ? '34px' : '10px'}
        >
          {readOnly && (
            <Fab color="primary" onClick={e => setReadOnly(false)}>
              <Edit />
            </Fab>
          )}
          {!readOnly && (
            <Fab color="primary" onClick={handleSave} disabled={!canSave()}>
              <Save />
            </Fab>
          )}
          {!isNew && (
            <Fab
              color="secondary"
              onClick={e => setConfirmDelete(true)}
              style={{ marginLeft: spacing(1) }}
            >
              <Delete />
            </Fab>
          )}
        </Box>
      )}
      {saving && (
        <SavingBox text={`${isNew ? 'Création' : 'Mise à jour'} du lieu...`} />
      )}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Confirmer la suppression ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Supprimer ce lieu entrainera sa suppression dans les manifestations
            associées, et la suppression des évènements s'y déroulant.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      {deleting && <SavingBox text="Suppression du lieu..." />}
    </Paper>
  )
}
