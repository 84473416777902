import { useReducer } from 'react'
import { constants } from '@readymade/shared'

const {
  organizer: { ROLE },
} = constants

const initialState = {
  lastName: '',
  firstName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  role: ROLE.EDITOR,
  organizerName: '',
  organizerUrl: '',
  organizerPhone: '',
  organizerEmail: '',
  organizerImage: null,
  fixedPlace: false,
  onApp: false,
  places: [],
  openingsDescription: '',
  openings: [],
}

export default organizer => {
  return useReducer(
    (state, { type, payload }) => {
      switch (type) {
        case 'lastName':
        case 'firstName':
        case 'email':
        case 'password':
        case 'passwordConfirm':
        case 'organizerName':
        case 'organizerUrl':
        case 'organizerEmail':
        case 'organizerPhone':
        case 'organizerImage':
        case 'openingsDescription':
        case 'onApp':
          return { ...state, [type]: payload }
        case 'role':
          return { ...state, role: payload ? ROLE.ADMIN : ROLE.EDITOR }
        case 'fixedPlace': {
          const openings = payload ? state?.openings ?? [] : null
          const places = payload ? state?.places ?? [] : []
          const openingsDescription = payload
            ? state?.openingsDescription ?? ''
            : ''
          const onApp = payload ? state.onApp : false
          return {
            ...state,
            fixedPlace: payload,
            onApp,
            openings,
            places,
            openingsDescription,
          }
        }
        case 'place':
          return { ...state, places: payload }
        case 'openings.update': {
          const openings = Array.from(state.openings)
          openings[payload.index] = payload.opening
          return { ...state, openings }
        }
        case 'openings.new': {
          const openings = Array.from(state.openings)
          openings.push({
            dayOfWeek: openings.length % 7,
            start: '15:00',
            end: '19:00',
          })
          return { ...state, openings }
        }
        case 'openings.remove': {
          const openings = Array.from(state.openings)
          openings.splice(payload, 1)
          return { ...state, openings }
        }
        default:
          return state
      }
    },
    organizer
      ? { ...organizer, password: '', passwordConfirm: '' }
      : initialState
  )
}
