import React from 'react'
import Input from '../Input'
import { Paper } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'

export default ({ start, readOnly, onChange }) => {
  const { spacing } = useTheme()
  return (
    <Input label="Date de la manifestation">
      <Paper style={{ padding: spacing(1), maxWidth: '325px' }}>
        <DatePicker
          disableToolbar
          variant="static"
          clearLabel="vider"
          cancelLabel="annuler"
          value={start}
          onChange={e => !readOnly && onChange(e)}
        />
      </Paper>
    </Input>
  )
}
