import React from 'react'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'
import { Link as RouterLink, useLocation } from 'react-router-dom'

const LinkRouter = props => <Link {...props} component={RouterLink} />

const breadcrumbNameMap = {
  '/manifestations': 'Manifestations',
  '/events': 'Évènements',
  '/places': 'Lieux',
  '/organizers': 'Organisateurs',
}

export default () => {
  const { pathname } = useLocation()
  const segments = pathname.split('/').filter(x => x) // filter removes first empty segment
  // console.log(segments)
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <LinkRouter color="inherit" to="/">
        Accueil
      </LinkRouter>
      {segments.map((value, index) => {
        const last = index === segments.length - 1
        const to = `/${segments.slice(0, index + 1).join('/')}`
        return last ? (
          <Typography color="textPrimary" key={to}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter color="inherit" to={to} key={to}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        )
      })}
    </Breadcrumbs>
  )
}
