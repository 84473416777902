import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { constants } from '@readymade/shared'
import OrganizerCard from '../../components/Organizers/OrganizerCard'

import useStore from '../../store'
import useFuse from '../../hooks/useFuse'
import NewItem from '../../components/NewItem'

const {
  organizer: { ROLE },
} = constants

const useStyles = makeStyles(theme => ({
  root: {},
  input: {
    width: '360px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  results: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: theme.breakpoints.values.lg,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}))

export default () => {
  const classes = useStyles()
  const user = useStore(state => state.user)
  const isAdmin = user.role === ROLE.ADMIN
  const organizers = useStore(state => state.organizers)
  const options = {
    keys: ['organizerName', 'organizerPhone', 'organizerEmail'],
  }
  const { result, search, term } = useFuse({
    data: organizers,
    options,
  })

  return (
    <Box className={classes.root}>
      <TextField
        color="secondary"
        className={classes.input}
        value={term}
        onChange={e => search(e.target.value)}
        placeholder="Rechercher un organisateur..."
      />
      <Box className={classes.results}>
        {result.map((o, i) => (
          <OrganizerCard key={i} organizer={o} />
        ))}
      </Box>
      {isAdmin && <NewItem item="organizer" />}
    </Box>
  )
}
