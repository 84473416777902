import { useReducer } from 'react'

const initialState = {
  name: '',
  address: '',
  longitude: null,
  latitude: null,
}

export default place => {
  return useReducer((state, { type, payload }) => {
    switch (type) {
      case 'name':
        return { ...state, name: payload }
      case 'address':
        return {
          ...state,
          address: payload?.address ?? '',
          longitude: payload?.longitude ?? null,
          latitude: payload?.latitude ?? null,
        }
      default:
        return state
    }
  }, place ?? initialState)
}
