import { useReducer } from 'react'
import { constants } from '@readymade/shared'
import useStore from '../../store'

const {
  manifestation: { TYPE, STATUS },
  organizer: { ROLE },
} = constants

const now = new Date()
const initialState = user => ({
  type: TYPE.EXPOSITION,
  status: STATUS.NON_PROGRAMMEE,
  organizers: user.role === ROLE.ADMIN ? [] : [user.id],
  title: '',
  artists: '',
  description: '',
  url: '',
  image: null,
  start: now,
  end: now,
  openingsDescription: '',
  openings: [],
  isPromo: false,
  places: [],
  events: [],
})

export default manifestation => {
  const deleteEvent = useStore(state => state.deleteEvent)
  const user = useStore(state => state.user)
  return useReducer((state, { type, payload }) => {
    switch (type) {
      case 'title':
      case 'description':
      case 'url':
      case 'artists':
      case 'start':
      case 'end':
      case 'status':
      case 'image':
      case 'openingsDescription':
        return { ...state, [type]: payload }
      case 'type': {
        const newState = { ...state, type: payload }
        switch (payload) {
          case TYPE.PERENNE:
            // no start, no end dates
            newState.start = newState.end = null
            // a single place at max
            newState.places = newState.places[0] ? [newState.places[0]] : []
            // reset openings if they are not present
            if (newState?.openings?.[0]?.dayOfWeek === null) {
              const start = newState.openings?.[0]?.start ?? '18:00'
              newState.openings = [{ dayOfWeek: 0, start, end: '19:00' }]
            }
            break
          case TYPE.PERFORMANCE_CONFERENCE: {
            // reset start date if not present
            newState.start = newState?.start ?? new Date()
            // no end date
            newState.end = null
            // a single opening
            const start = newState.openings?.[0]?.start ?? '18:00'
            newState.openings = [{ dayOfWeek: null, start, end: null }]
            // a single place at max
            newState.places = newState.places[0] ? [newState.places[0]] : []
            // no events, delete them
            newState.events.forEach(e => deleteEvent(e))
            newState.events = []
            break
          }
          default: {
            // reset dates if they are not present
            newState.start = newState?.start ?? new Date()
            newState.end = newState?.end ?? new Date()
            // reset openings if they are not present
            if (newState?.openings?.[0]?.dayOfWeek === null) {
              const start = newState.openings?.[0]?.start ?? '18:00'
              newState.openings = [{ dayOfWeek: 0, start, end: '19:00' }]
            }
            break
          }
        }
        return newState
      }
      case 'organizers.add': {
        const organizers = Array.from(state.organizers)
        organizers.push(...payload)
        return { ...state, organizers }
      }
      case 'organizers.remove': {
        const organizers = Array.from(state.organizers)
        organizers.splice(payload, 1)
        return { ...state, organizers }
      }
      case 'openings.update': {
        const openings = Array.from(state.openings)
        openings[payload.index] = payload.opening
        return { ...state, openings }
      }
      case 'openings.new': {
        const openings = Array.from(state.openings)
        openings.push({
          dayOfWeek: openings.length % 7,
          start: '15:00',
          end: '19:00',
        })
        return { ...state, openings }
      }
      case 'openings.remove': {
        const openings = Array.from(state.openings)
        openings.splice(payload, 1)
        return { ...state, openings }
      }
      case 'openings.single': {
        return {
          ...state,
          openings: [{ dayOfWeek: undefined, start: payload, end: undefined }],
        }
      }
      case 'places.remove': {
        const places = Array.from(state.places)
        places.splice(payload, 1)
        return { ...state, places }
      }
      case 'places.add': {
        const places = Array.from(state.places)
        places.push(...payload)
        return { ...state, places }
      }
      case 'places.new': {
        const places = Array.from(state.places)
        places.push(payload)
        return { ...state, places }
      }
      case 'events.remove': {
        const events = Array.from(state.events)
        events.splice(payload, 1)
        return { ...state, events }
      }
      case 'events.new': {
        const events = Array.from(state.events)
        events.push(payload)
        return { ...state, events }
      }
      default:
        return state
    }
  }, manifestation ?? initialState(user))
}
