import React, { useState } from 'react'
import {
  TableContainer,
  Paper,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Delete, Add, Search, Launch } from '@material-ui/icons'
import Input from '../Input'
import NewPlaceDIalog from './NewPlaceDIalog'
import AddPlaceDialog from './AddPlaceDialog'
import { useHistory } from 'react-router-dom'

export default ({
  associatedPlaces,
  availablePlaces,
  singlePlace,
  readOnly,
  addPlaceTitle,
  newPlaceTitle,
  onRemove,
  onAdd,
  onNew,
}) => {
  const { breakpoints, spacing } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const history = useHistory()

  const [addModal, setAddModal] = useState(false)
  const [newModal, setNewModal] = useState(false)

  const handleAdd = additionalPlaces => {
    onAdd(additionalPlaces)
  }

  const handleNew = newPlace => {
    onNew(newPlace)
  }

  return (
    <Input label={`Adresse${singlePlace ? '' : '(s)'}`}>
      <TableContainer component={Paper}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell colSpan={2}>Adresse</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {associatedPlaces.map((p, i) => (
              <TableRow
                key={i}
                style={{ height: readOnly ? '61px' : 'inherit' }}
              >
                <TableCell>
                  {p.name}
                  {largeScreen && (
                    <IconButton
                      onClick={e => history.push(`/places/${p.id}`)}
                      size="small"
                      style={{ paddingBottom: '4px' }}
                    >
                      <Launch style={{ width: '0.6em', height: '0.6em' }} />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>{p.address}</TableCell>
                {!readOnly && (
                  <TableCell align="right">
                    <IconButton
                      onClick={e => onRemove(i)}
                      style={{ marginLeft: 'auto' }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!readOnly && (
        <Box
          mt={1}
          display="flex"
          flexDirection={largeScreen ? 'row' : 'column'}
          alignItems="flex-start"
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={singlePlace && associatedPlaces.length >= 1}
            startIcon={<Search />}
            onClick={e => setAddModal(true)}
          >
            Choisir une adresse
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            disabled={singlePlace && associatedPlaces.length >= 1}
            style={{ [largeScreen ? 'marginLeft' : 'marginTop']: spacing(1) }}
            onClick={e => setNewModal(true)}
          >
            Ajouter une adresse
          </Button>
        </Box>
      )}
      <AddPlaceDialog
        title={addPlaceTitle}
        open={addModal}
        singlePlace={singlePlace}
        availablePlaces={availablePlaces}
        onClose={() => setAddModal(false)}
        onAdded={handleAdd}
      />
      <NewPlaceDIalog
        title={newPlaceTitle}
        open={newModal}
        onClose={() => setNewModal(false)}
        onCreated={handleNew}
      />
    </Input>
  )
}
