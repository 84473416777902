import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Paper, Box } from '@material-ui/core'

import { constants } from '@readymade/shared'
import DetailTitle from '../../components/DetailTitle'
import TextField from '../../components/TextField'
import RichText from '../../components/RichText'
import Dropzone from '../../components/Dropzone'
import Places from '../../components/Places'
import Organizers from '../../components/Manifestation/Organizers'
import Type from '../../components/Manifestation/Type'
import Dates from '../../components/Manifestation/Dates'
import SingleDate from '../../components/Manifestation/SingleDate'
import Openings from '../../components/Openings'
import SingleOpening from '../../components/Manifestation/SingleOpening'
import Events from '../../components/Manifestation/Events'
import EditButtons from '../../components/Manifestation/EditButtons'
import Status from '../../components/Manifestation/Status'
import SavingBox from '../../components/SavingBox'

import createReducer from './reducer'
import useStore from '../../store'
// const useStyles = makeStyles(theme => {})

const {
  manifestation: { TYPE },
  organizer: { ROLE },
} = constants

const Manifestation = () => {
  //////////////////////////////////////////////////
  // load from store
  const user = useStore(state => state.user)
  const manifestations = useStore(state => state.manifestations)
  const places = useStore(state => state.places)
  const events = useStore(state => state.events)
  const organizers = useStore(state => state.organizers)
  const createManifestation = useStore(state => state.createManifestation)
  const updateManifestation = useStore(state => state.updateManifestation)
  const deleteManifestation = useStore(state => state.deleteManifestation)
  const fetchAll = useStore(state => state.fetchAll)
  const fetchEvents = useStore(state => state.fetchEvents)
  const history = useHistory()
  const { id } = useParams()
  const isNew = id === 'new'
  const [readOnly, setReadOnly] = useState(!isNew)
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  //////////////////////////////////////////////////
  // state
  const manifestation = isNew
    ? null
    : manifestations.find(m => m.id === parseInt(id, 10))
  // state
  const [state, dispatch] = createReducer(manifestation)
  const handleChange = (type, payload) => {
    dispatch({ type, payload })
  }

  const singleDate = state.type === TYPE.PERFORMANCE_CONFERENCE
  const noDate = state.type === TYPE.PERENNE
  const isAdmin = user.role === ROLE.ADMIN
  const canEdit = isAdmin || state.organizers.includes(user.id)

  //////////////////////////////////////////////////
  // mapping organizers to manifestations
  const [associatedOrganizers, setAssociatedOrganizers] = useState(
    organizers.filter(o => state.organizers.includes(o.id))
  )
  const [availableOrganizers, setAvailableOrganizers] = useState(
    organizers.filter(o => !state.organizers.includes(o.id))
  )
  useEffect(() => {
    // doing this way to avoid reordering of state.organizers
    setAssociatedOrganizers(
      state.organizers.map(current => organizers.find(o => o.id === current))
    )
    setAvailableOrganizers(
      organizers.filter(o => !state.organizers.includes(o.id))
    )
  }, [organizers, state.organizers])

  //////////////////////////////////////////////////
  // mapping places to manifestations
  const [associatedPlaces, setAssociatedPlaces] = useState(
    places.filter(p => state.places.includes(p.id))
  )
  const [availablePlaces, setAvailablePlaces] = useState(
    places.filter(p => !state.places.includes(p.id))
  )
  useEffect(() => {
    // doing this way to avoid reordering of state.places
    setAssociatedPlaces(
      state.places.map(current => places.find(p => p.id === current))
    )
    setAvailablePlaces(places.filter(p => !state.places.includes(p.id)))
  }, [places, state.places])

  //////////////////////////////////////////////////
  // mapping events to manifestations
  const [associatedEvents, setAssociatedEvents] = useState(() => {
    const currentEvents = events.filter(e => state.events.includes(e.id))
    return currentEvents.map(e => {
      const place = places.find(p => p.id === e.placeId)
      return { ...e, place }
    })
  })
  useEffect(() => {
    // new events on state, gather linked data
    const currentEvents = state.events
      .map(current => events.find(e => e.id === current))
      .filter(e => e) // filter unefined events
    // gather place data for the new events
    const updatedEvents = currentEvents.map(e => {
      const place = places.find(p => p.id === e.placeId)
      return { ...e, place }
    })
    setAssociatedEvents(updatedEvents)
  }, [events, places, state.events])

  //////////////////////////////////////////////////
  // handlers
  useEffect(() => {
    const save = async () => {
      setReadOnly(true)
      if (isNew) {
        const id = await createManifestation(state)
        await fetchEvents()
        history.push(`/manifestations/${id}`)
      } else {
        await updateManifestation({ ...state, id })
        await fetchEvents()
      }
      setSaving(false)
    }
    if (saving) save()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const handleSave = status => {
    if (isNew) handleChange('status', status)
    setSaving(true)
  }

  useEffect(() => {
    const del = async () => {
      await deleteManifestation(id)
      await fetchAll()
      setDeleting(false)
      history.push('/manifestations')
    }
    if (deleting) del()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleting])

  const handleDelete = async event => {
    setDeleting(true)
  }

  return (
    <Paper>
      <Box component="form" p={2}>
        {/* title */}
        <DetailTitle title={state.title} />
        {/* type */}
        <Type
          value={state.type}
          onChange={value => handleChange('type', value)}
          readOnly={readOnly}
        />
        {/* status */}
        {!isNew && (
          <Status
            value={state.status}
            onChange={value => handleChange('status', value)}
            readOnly={readOnly}
          />
        )}
        {/* titre */}
        <TextField
          label="Titre de la manifestation"
          value={state.title}
          readOnly={readOnly}
          onChange={value => handleChange('title', value)}
        />
        {/* organizers */}
        <Organizers
          associatedOrganizers={associatedOrganizers}
          availableOrganizers={availableOrganizers}
          isAdmin={isAdmin}
          readOnly={readOnly}
          onRemove={index => handleChange('organizers.remove', index)}
          onAdd={organizersId => handleChange('organizers.add', organizersId)}
        />
        {/* artistes associés */}
        <RichText
          toolbarId="artists"
          label="Artistes et/ou intervenants associés à cette manifestation"
          value={state.artists}
          readOnly={readOnly}
          maxLength={2000}
          onChange={value => handleChange('artists', value)}
        />
        {/* description */}
        <RichText
          toolbarId="description"
          label="Description de la manifestation (Ne pas mettre d'informations pratiques)"
          value={state.description}
          readOnly={readOnly}
          maxLength={4000}
          onChange={value => handleChange('description', value)}
        />
        {/* url */}
        <TextField
          label="Lien web de la manifestation (site web, post facebook, etc.)"
          value={state.url ?? ''}
          readOnly={readOnly}
          onChange={value => handleChange('url', value)}
        />
        {/* image */}
        <Dropzone
          label="Image de la manifestation (Privilégier un format paysage ou carré, environ 800px de largeur). Image seule sans texte."
          value={state.image}
          readOnly={readOnly}
          onChange={file => handleChange('image', file)}
        />
        {/* dates */}
        {singleDate && (
          <SingleDate
            start={state.start}
            readOnly={readOnly}
            onChange={date => handleChange('start', date)}
          />
        )}
        {!noDate && !singleDate && (
          <Dates
            start={state.start}
            end={state.end}
            readOnly={readOnly}
            onStartChange={date => handleChange('start', date)}
            onEndChange={date => handleChange('end', date)}
          />
        )}
        {/* horaires */}
        <TextField
          label="Infos pratiques"
          value={state.openingsDescription ?? ''}
          readOnly={readOnly}
          onChange={value => handleChange('openingsDescription', value)}
        />
        {singleDate ? (
          <SingleOpening
            openings={state.openings}
            readOnly={readOnly}
            onChange={start => handleChange('openings.single', start)}
          />
        ) : (
          <Openings
            openings={state.openings}
            readOnly={readOnly}
            onUpdate={e => handleChange('openings.update', e)}
            onRemove={index => handleChange('openings.remove', index)}
            onNew={e => handleChange('openings.new')}
          />
        )}
        {/* places */}
        <Places
          associatedPlaces={associatedPlaces}
          availablePlaces={availablePlaces}
          singlePlace={singleDate || noDate}
          readOnly={readOnly}
          addPlaceTitle="Sélectionner une adresse pour cette manifestation"
          newPlaceTitle="Ajouter une adresse pour cette manifestation"
          onRemove={index => handleChange('places.remove', index)}
          onAdd={placesId => handleChange('places.add', placesId)}
          onNew={placeId => handleChange('places.new', placeId)}
        />
        {/* events */}
        {!singleDate && (
          <Events
            associatedEvents={associatedEvents}
            initialAddress={state.places?.[0]}
            readOnly={readOnly}
            onRemove={index => handleChange('events.remove', index)}
            onNew={eventId => handleChange('events.new', eventId)}
          />
        )}
      </Box>
      {/* edit buttons, saving, deleting */}
      {(canEdit || isNew) && (
        <EditButtons
          state={state}
          readOnly={readOnly}
          onEdit={() => setReadOnly(false)}
          onSave={handleSave}
          onDelete={handleDelete}
          onUpdate={handleSave}
        />
      )}
      {saving && (
        <SavingBox
          text={`${isNew ? 'Création' : 'Mise à jour'} de la manifestation...`}
        />
      )}
      {deleting && <SavingBox text="Suppression de la manifestation..." />}
    </Paper>
  )
}

// Manifestation.whyDidYouRender = true
export default Manifestation
