import React, { useState } from 'react'
import {
  TableContainer,
  Paper,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Delete, Add, Edit } from '@material-ui/icons'
import Input from '../Input'
import { format } from 'date-fns'
import EventDialog from './EventDialog'
import useStore from '../../store'

export default ({
  associatedEvents,
  initialAddress,
  readOnly,
  onRemove,
  onNew,
}) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const deleteEvent = useStore(state => state.deleteEvent)
  const [modal, setModal] = useState(false)
  const [event, setEvent] = useState(false)

  const newEvent = () => {
    setModal(true)
    setEvent(null)
  }

  const editEvent = event => {
    setModal(true)
    setEvent(event)
  }

  const handleNew = newEvent => {
    onNew(newEvent)
  }

  const handleRemove = async (index, id) => {
    await deleteEvent(id)
    onRemove(index)
  }

  return (
    <Input label="Agenda">
      <TableContainer component={Paper}>
        <MuiTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Date</TableCell>
              <TableCell colSpan={largeScreen ? 0 : 2}>Horaire</TableCell>
              {largeScreen && <TableCell colSpan={2}>Lieu</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {associatedEvents.map((e, i) => (
              <TableRow
                key={i}
                style={{ height: readOnly ? '61px' : 'inherit' }}
              >
                <TableCell>{e.type}</TableCell>
                <TableCell>{e.title}</TableCell>
                <TableCell>{format(e.date, 'dd/MM/yyyy')}</TableCell>
                <TableCell>{e.opening}</TableCell>
                {largeScreen && <TableCell>{e.place.name}</TableCell>}
                {!readOnly && (
                  <TableCell align="right">
                    <IconButton
                      onClick={evt => editEvent(e)}
                      style={{ marginLeft: 'auto' }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={evt => handleRemove(i, e.id)}
                      style={{ marginLeft: 'auto' }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!readOnly && (
        <Box
          mt={1}
          display="flex"
          flexDirection={largeScreen ? 'row' : 'column-reverse'}
          alignItems={largeScreen ? 'center' : 'flex-start'}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={newEvent}
          >
            Ajouter un évènement
          </Button>
        </Box>
      )}
      <EventDialog
        open={modal}
        event={event}
        initialAddress={initialAddress}
        onClose={() => setModal(false)}
        onCreated={handleNew}
      />
    </Input>
  )
}
