import React from 'react'
import MaterialTable from 'material-table'

import localization from './localization'
import tableIcons from './tableIcons'

export default ({
  columns,
  data,
  components,
  options,
  onRowClick,
  ...tableProps
}) => {
  const combinedOptions = {
    showTitle: false,
    pageSize: 30,
    pageSizeOptions: [30, 50, 100],
    emptyRowsWhenPaging: false,
    ...options,
  }

  return (
    <MaterialTable
      columns={columns}
      data={data}
      icons={tableIcons}
      options={combinedOptions}
      localization={localization}
      components={components}
      onRowClick={onRowClick}
      {...tableProps}
    />
  )
}
