import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Paper, Typography, Box } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import frLocale from '@fullcalendar/core/locales/fr'
import interactionPlugin from '@fullcalendar/interaction'
import { utils } from '@readymade/shared'

import useStore from '../../store'

const { getManifestationColor } = utils

const useStyles = makeStyles(theme => ({
  calendar: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    '& .fc-widget-header': {
      border: 'hidden',
      marginRight: 0,
    },
    '& td > div.fc-scroller': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .fc-day-header': {
      verticalAlign: 'middle',
      height: '45px',
      background: theme.palette.grey[50],
    },
    '& .fc-toolbar': {
      display: 'none',
    },
    '& .fc th, & .fc td': {
      borderStyle: 'hidden',
    },
    '& .fc .fc-day': {
      borderColor: theme.palette.grey[50],
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    '& .fc .fc-today': {
      background: theme.palette.grey[50],
    },
    '& .fc .fc-event': {
      color: 'inherit',
      borderStyle: 'hidden',
      cursor: 'pointer',
    },
    '& .fc .fc-title': {
      fontWeight: 'bold',
    },
  },
  month: {
    textTransform: 'capitalize',
    width: '125px',
  },
}))

export default props => {
  const classes = useStyles()
  const calendarRef = useRef(null)
  const { status } = useTheme()
  const history = useHistory()
  const [monthTitle, setMonthTitle] = useState('')
  const manifestations = useStore(state => state.manifestations)
  const events = useStore(state => state.events)

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    setMonthTitle(calendarApi.view.title)
  }, [calendarRef])

  const handlePrev = () => {
    if (calendarRef) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.prev()
      setMonthTitle(calendarApi.view.title)
    }
  }

  const handleNext = () => {
    if (calendarRef) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.next()
      setMonthTitle(calendarApi.view.title)
    }
  }

  const handleEventClick = ({ event, jsEvent }) => {
    jsEvent.preventDefault()
    history.push(event.url)
  }

  const [calendarManifestations, setCalendarManifestations] = useState([])
  useEffect(() => {
    setCalendarManifestations(
      manifestations
        .map(m => {
          const color = getManifestationColor(m.status, status)
          const start = {
            ...m,
            color,
            prefix: `Début - ${m.type}`,
            url: `/manifestations/${m.id}`,
          }
          delete start.end
          const end = {
            ...m,
            color,
            prefix: `Fin - ${m.type}`,
            url: `/manifestations/${m.id}`,
          }
          end.start = end.end
          delete end.end
          return [start, end]
        })
        .flat()
    )
  }, [manifestations, status])

  const [calendarEvents, setCalendarEvents] = useState([])
  useEffect(() => {
    setCalendarEvents(
      events
        .map(e => {
          if (e.manifestationId) {
            return {
              ...e,
              color: status.event,
              start: e.date,
              prefix: e.type,
              url: `/manifestations/${e.manifestationId}`,
            }
          } else return null
        })
        .filter(x => x)
    )
  }, [events, status.event])

  return (
    <Paper className={classes.calendar}>
      <Box display="flex" alignItems="center" paddingLeft="16px">
        <Typography
          variant="subtitle1"
          component="span"
          className={classes.month}
        >
          {monthTitle}
        </Typography>
        <IconButton color="primary" component="span" onClick={handlePrev}>
          <ChevronLeft color="action" />
        </IconButton>
        <IconButton color="primary" component="span" onClick={handleNext}>
          <ChevronRight color="action" />
        </IconButton>
      </Box>
      <Box flexGrow={1}>
        <FullCalendar
          ref={calendarRef}
          defaultView="dayGridMonth"
          height="parent"
          locale={frLocale}
          fixedWeekCount={false} // always display 6 weeks (with prev and next month)
          eventClick={handleEventClick}
          nextDayThreshold="09:00:00" // don't span on next day
          displayEventTime={false}
          plugins={[dayGridPlugin, interactionPlugin]}
          eventRender={data => {
            const prefix = data.event.extendedProps.prefix
            const span = document.createElement('span')
            span.style.textOverflow = 'ellipsis'
            span.style.overflow = 'hidden'
            span.style.display = 'inline-block'
            span.style.whiteSpace = 'nowrap'
            span.style.width = '100%'
            span.textContent = prefix
            data.el.prepend(span)
          }}
          events={[...calendarManifestations, ...calendarEvents]}
        />
      </Box>
    </Paper>
  )
}
