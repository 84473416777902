import React from 'react'
import { Box, FormLabel, FormControl } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

const FieldSet = ({ maxWidth, children }) => {
  const { breakpoints } = useTheme()
  return (
    <FormControl
      component="fieldset"
      style={{
        width: '100%',
        maxWidth: maxWidth || `${breakpoints.values.md}px`,
      }}
    >
      {children}
    </FormControl>
  )
}

export default ({ label, mt, ml, mr, mb, maxWidth, children, ...boxProps }) => {
  return (
    <Box mt={mt || 6} ml={ml || 0} mr={mr || 0} mb={mb || 0} {...boxProps}>
      <FieldSet maxWidth={maxWidth || null}>
        <Box mb={1}>
          <FormLabel component="legend" focused={false}>
            {label}
          </FormLabel>
        </Box>
        {children}
      </FieldSet>
    </Box>
  )
}
