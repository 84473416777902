import { manifestation } from '../constants/index.js'
import { places } from '../constants/index.js'

import groupBy from 'lodash.groupby'
// const { groupBy } = pkg

import {
  set,
  startOfDay,
  isBefore,
  differenceInDays,
  isToday,
  isWithinInterval,
  parse,
} from 'date-fns'

const { STATUS, TYPE } = manifestation
const { FILTERS } = places

const getManifestationColor = (status, stateColors) => {
  switch (status) {
    case STATUS.PROGRAMMEE:
      return stateColors.programmed
    case STATUS.NON_PROGRAMMEE:
      return stateColors.notProgrammed
    case STATUS.A_CONFIRMER:
      return stateColors.tbc
    default:
      return null
  }
}

const getTimeSlot = (manifestation, dateToCompare) => {
  switch (manifestation.type) {
    case TYPE.PERENNE:
      return 'permanent'
    case TYPE.PERFORMANCE_CONFERENCE:
      const date = manifestation.start
      const [hours, minutes] = manifestation.openings[0].start.split(':')
      const start = set(date, { hours, minutes, seconds: 0, milliseconds: 0 })
      if (start <= dateToCompare) return 'past'
      else return 'upcoming'
    default:
      if (manifestation.end < dateToCompare) return 'past'
      else if (manifestation.start > dateToCompare) return 'upcoming'
      else return 'current'
  }
}

const temporalSort = data => {
  const now = new Date()
  // console.log(groupBy)
  return groupBy(data, d => {
    return getTimeSlot(d, now)
  })
}

const computeDates = item => {
  if (item) {
    if (item.hasOwnProperty('start') && item.hasOwnProperty('end'))
      return {
        ...item,
        start: item.start ? new Date(item.start) : null,
        end: item.end ? new Date(item.end) : null,
      }
    if (item.hasOwnProperty('begin_at') || item.hasOwnProperty('finish_at'))
      return {
        ...item,
        start: item.begin_at ? new Date(item.begin_at) : null,
        end: item.finish_at ? new Date(item.finish_at) : null,
      }
    if (item.hasOwnProperty('date'))
      return {
        ...item,
        date: item.date ? new Date(item.date) : null,
      }
  }
  return item
}

/**
 * Checks if a manifestation is ending within
 * the next `delta` days compared to the given date
 * @param {Object} manifestation - the manifestation to check
 * @param {Number} delta - delta in days, defaults to 7
 */
const isEndingManifestation = (manifestation, delta = 7) => {
  const today = startOfDay(new Date())
  if (isBefore(today, manifestation.start)) return false
  else if (manifestation.end) {
    const d = differenceInDays(startOfDay(manifestation.end), today)
    return d > 0 && d <= delta
  } else if (manifestation.start) {
    const d = differenceInDays(startOfDay(manifestation.start), today)
    return d > 0 && d <= delta
  } else return false
}

/**
 * Checks if an event is happening today
 * the next `delta` days compared to the given date
 * @param {Object} manifestation - the manifestation to check
 */
const isEventToday = manifestation => {
  return (
    manifestation.events &&
    manifestation.events.some(event => isToday(event.date))
  )
}

/**
 * Checks if a manifestation is currently open
 * @param {Object} manifestation - the manifestation to check
 */
const isOnGoingManifestation = ({ start, end }) => {
  const today = startOfDay(new Date())
  if (start && end) return isWithinInterval(today, { start, end })
  else return false
}

/**
 * Check if manifestation is Permanent
 * @param {*} manifestation
 */
const isPermanentManifestation = manifestation => {
  return manifestation.type === TYPE.PERENNE
}

/**
 * Check if manifestation is a promo
 * @param {*} manifestation
 */
const isHighlitedManifestation = manifestation => {
  return manifestation.isPromo
}
/**
 * Checks if a manifestation is ending today
 * @param {Object} manifestation - current manifestation to check
 */
const isTodayEndingManifestation = manifestation => {
  return isEndingManifestation(manifestation, 0)
}

/**
 * Check if a manifestation is Upcoming
 * @param {Object} manifestation - current manifestation to check
 * @return {Boolean}
 */
const isUpcomingManifestation = manifestation => {
  return isBefore(startOfDay(new Date()), manifestation.start)
}

/**
 * Checks if a place have a manifestation ending within
 * the next `delta` days
 * @param {Object} place - current place to check
 * @param {Number} delta - delta in days, defaults to 7
 */
const hasEndingManifestation = (place, delta = 7) => {
  return place.manifestations.some(m => isEndingManifestation(m, delta))
}

/**
 * Checks if a place have a manifestation ending within
 * the next `delta` days
 * @param {Object} place - current place to check
 * @param {Number} delta - delta in days, defaults to 7
 */
const hasOnGoingManifestation = place => {
  return place.manifestations.some(m => isOnGoingManifestation(m))
}

/**
 * Checks if a place have a manifestation Permanent
 * @param {Object} place - current place to check
 */
const hasPermanentManifestation = place => {
  return place.manifestations.some(m => isPermanentManifestation(m))
}

/**
 * Checks if a place have an event today
 * @param {Object} place - current place to check
 */
const hasEventToday = place => {
  return place.manifestations.some(m => isEventToday(m))
}

/**
 * Checks if a place have a manifestation ending today
 * @param {Object} place - current place to check
 */
const hasTodayEndingManifestation = place => {
  return hasEndingManifestation(place, 0)
}

/**
 * Checks if a place have a manifestation Highlited
 * @param {Object} place - current place to check
 */
const hasHighlitedManifestation = place => {
  return place.manifestations.some(m => m.isPromo)
}

/**
 * Get the filter of a manifestation
 * @param {Object} manifestation - current manifestation to check
 * @return {String} - the filter
 */
const getManifestationFilter = manifestation => {
  // if (isHighlitedManifestation(manifestation)) return FILTERS.HIGHLIGHTED
  if (isTodayEndingManifestation(manifestation) || isEventToday(manifestation))
    return FILTERS.TODAY
  if (isEndingManifestation(manifestation)) return FILTERS.OVERSOON
  if (isOnGoingManifestation(manifestation)) return FILTERS.CURRENT
  if (isPermanentManifestation(manifestation)) return FILTERS.PERENNE
  if (isUpcomingManifestation(manifestation)) return FILTERS.UPCOMING
  return null
}

export {
  getManifestationColor,
  temporalSort,
  isEndingManifestation,
  isEventToday,
  isOnGoingManifestation,
  isPermanentManifestation,
  isHighlitedManifestation,
  isTodayEndingManifestation,
  isUpcomingManifestation,
  hasEndingManifestation,
  hasOnGoingManifestation,
  hasPermanentManifestation,
  hasEventToday,
  hasTodayEndingManifestation,
  hasHighlitedManifestation,
  computeDates,
  getManifestationFilter,
}
