import React, { useState, useEffect } from 'react'
import {
  Paper,
  Box,
  Typography,
  Button,
  Dialog,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'

import Table from '../../components/Table'

import useStore from '../../store'
import SavingBox from '../../components/SavingBox'

const LinkRouter = props => <Link {...props} component={RouterLink} />

export default () => {
  // responsive
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  // load from store
  const manifestations = useStore(state => state.manifestations)
  const promos = manifestations.filter(m => m.isPromo)
  const updateManifestation = useStore(state => state.updateManifestation)
  const generateNewsletter = useStore(state => state.generateNewsletter)
  // state
  const [modal, setModal] = useState(false)
  const [currentPromos, setCurrentPromos] = useState(promos)
  const [saving, setSaving] = useState(false)
  const [generating, setGenerating] = useState(false)

  const handleClick = () => {
    setModal(true)
  }
  const handleClose = () => {
    setCurrentPromos(promos)
    setModal(false)
  }
  const handleSubmit = () => {
    setSaving(true)
    setModal(false)
  }
  useEffect(() => {
    const save = async () => {
      if (
        promos.map((p, i) => p.id).join(',') !==
        currentPromos.map((p, i) => p.id).join(',')
      ) {
        const newPromos = currentPromos.filter(p => {
          return !promos.find(c => c.id === p.id)
        })
        const oldPromos = promos.filter(p => {
          return !currentPromos.find(c => c.id === p.id)
        })

        for (const promo of oldPromos) {
          await updateManifestation({ ...promo, isPromo: false }, true)
        }

        for (const promo of newPromos) {
          await updateManifestation({ ...promo, isPromo: true }, true)
        }
      }
      setSaving(false)
    }
    if (saving) save()
  }, [currentPromos, promos, saving, updateManifestation])

  const handleSelectionChange = rows => {
    // const row = rows.find(r => currentPromos[0]?.id !== r.id)
    setCurrentPromos(rows)
    // else setCurrentPromos(null)
  }

  const handleNewsletter = async (monthOffset, promoId) => {
    setGenerating(true)
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1 + monthOffset
    const text = await generateNewsletter(year, month, promoId)
    const data = new Blob([text], { type: 'text/html' })
    const nlUrl = window.URL.createObjectURL(data)
    const tempLink = document.createElement('a')
    tempLink.href = nlUrl
    tempLink.setAttribute('download', 'newsletter.html')
    tempLink.click()
    setGenerating(false)
  }

  return (
    <Paper style={{ height: '100%' }}>
      <Box p={2}>
        <Box mt={2} mb={2}>
          <Button color="primary" variant="contained" onClick={handleClick}>
            Choisir une ou plusieurs manifestations
          </Button>
        </Box>

        {currentPromos.length ? (
          <>
            <Typography color="textPrimary" variant="h5">
              {currentPromos.length > 1
                ? 'Les manifestations mises en avant sont:'
                : 'La manifestation mise en avant est:'}
            </Typography>
            {currentPromos.map(promo => (
              <Box
                key={promo.id}
                mb={2}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <LinkRouter to={`/manifestations/${promo.id}`}>
                  <Typography color="primary" variant="h5">
                    {promo.title}
                  </Typography>
                </LinkRouter>

                <Box ml={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={e => handleNewsletter(0, promo.id)}
                  >
                    Newsletter du mois courant
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={e => handleNewsletter(1, promo.id)}
                  >
                    Newsletter du mois suivant
                  </Button>
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <Typography color="textPrimary" variant="h5">
            Aucune manifestation mise en avant !
          </Typography>
        )}
      </Box>
      <Dialog
        open={modal}
        onClose={handleClose}
        PaperComponent={props => (
          <Paper {...props} style={{ width: largeScreen ? '60%' : '100%' }} />
        )}
        maxWidth="md"
      >
        <DialogTitle>
          Sélectionner la manifestation à mettre en avant dans l'application
        </DialogTitle>
        <DialogContent>
          <Table
            columns={[{ title: 'Titre', field: 'title' }]}
            data={manifestations.map((m, i) => ({
              ...m,
              tableData: {
                checked:
                  currentPromos.findIndex(el => el.id === m.id) >= 0
                    ? true
                    : false,
              },
            }))}
            options={{
              selection: true,
              pageSize: 10,
              pageSizeOptions: [],
              emptyRowsWhenPaging: true,
              padding: 'dense',
              showSelectAllCheckbox: false,
            }}
            onSelectionChange={handleSelectionChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="secondary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      {saving && (
        <SavingBox text="Mise à jour de la manifestation mise en avant..." />
      )}
      {generating && <SavingBox text="Génération de la newsletter..." />}
    </Paper>
  )
}
