export default {
  body: {
    emptyDataSourceMessage: 'Aucune ligne à afficher', // No records to display
    addTooltip: 'Ajouter', // Add
    deleteTooltip: 'Supprimer', // Delete
    editTooltip: 'Éditer', // Edit
    filterRow: {
      filterTooltip: 'Filtrer', // Filter
    },
    editRow: {
      deleteText: 'Êtes-vous sur de supprimer cette ligne ?', //Are you sure delete this row?
      cancelTooltip: 'Annuler', //Cancel
      saveTooltip: 'Enregistrer', //Save
    },
  },
  grouping: {
    placeholder: 'Déplacer les entêtes', // Drag headers ...
  },
  header: {
    actions: 'Actions', // Actions
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}', // {from}-{to} of {count}
    labelRowsSelect: 'lignes', // rows
    labelRowsPerPage: 'Lignes par page :', // Rows per page:
    firstAriaLabel: 'Première page', // First Page
    firstTooltip: 'Première page', // First Page
    previousAriaLabel: 'Page précédente', // Previous Page
    previousTooltip: 'Page précédente', // Previous Page
    nextAriaLabel: 'Page suivante', // Next Page
    nextTooltip: 'Page suivante', // Next Page
    lastAriaLabel: 'Dernière page', // Last Page
    lastTooltip: 'Dernière page', // Last Page
  },
  toolbar: {
    addRemoveColumns: 'Ajouter ou supprimer des lignes', // Add or remove columns
    nRowsSelected: '{0} ligne(s) sélectionnée(s)', // {0} row(s) selected
    showColumnsTitle: 'Afficher les colonnes', // Show Columns
    showColumnsAriaLabel: 'Afficher les colonnes', // Show Columns
    exportTitle: 'Exporter', // Export
    exportAriaLabel: 'Exporter', // Export
    exportName: 'Exporter au format CSV', // Export as CSV
    searchTooltip: 'Rechercher', // Search
    searchPlaceholder: 'Rechercher', // Search
  },
}
