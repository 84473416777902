import React, { useState, useEffect } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Room } from '@material-ui/icons'
import ReactMapGL, {
  FlyToInterpolator,
  Marker,
  NavigationControl,
} from 'react-map-gl'
import { easeCubic } from 'd3-ease'
import Geocoder from '../Geocoder'
import Input from '../Input'

export default ({ place, readOnly, onChange }) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))

  const initialViewport = {
    latitude: 47.2173,
    longitude: -1.5534,
    zoom: 8,
  }
  const [viewport, setViewport] = useState(initialViewport)
  const [marker, setMarker] = useState(place)

  const handleSelected = item => {
    onChange(item)
    if (item) {
      setMarker(item)
    } else {
      setMarker(null)
    }
  }

  useEffect(() => {
    if (marker?.latitude && marker?.longitude) {
      setViewport({
        ...viewport,
        longitude: marker.longitude,
        latitude: marker.latitude,
        transitionDuration: 'auto',
        transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
        transitionEasing: easeCubic,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker])

  const editProps = readOnly
    ? { draggable: false }
    : {
        draggable: true,
        onDragEnd(e) {
          const updatedMaker = {
            address: marker.address,
            longitude: e.lngLat[0],
            latitude: e.lngLat[1],
          }
          handleSelected(updatedMaker)
        },
      }

  return (
    <Input label="Adresse" mt={4}>
      <Geocoder
        initialValue={marker?.address ?? ''}
        readOnly={readOnly}
        gmapsApiAccessToken={process.env.REACT_APP_GMAPS_TOKEN}
        queryParams={{
          country: 'fr',
          bounds: [
            { lat: 47.476854, lon: -2.362656 },
            { lat: 46.578493, lon: 0.304349 },
          ],
        }}
        onSelected={handleSelected}
      />
      <ReactMapGL
        style={{ minWidth: '100%' }}
        height={400}
        {...viewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={setViewport}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      >
        {marker?.latitude && marker?.longitude && (
          <Marker
            latitude={marker.latitude}
            longitude={marker.longitude}
            offsetLeft={-20}
            offsetTop={-20}
            {...editProps}
          >
            <Room color="secondary" style={{ fontSize: 40 }} />
          </Marker>
        )}
        <Box
          position="absolute"
          pr={1}
          pb={largeScreen ? 3.5 : 5.5}
          style={{ bottom: 0, right: 0 }}
        >
          <NavigationControl showCompass={false} />
        </Box>
      </ReactMapGL>
    </Input>
  )
}
