import React, { useState, useEffect } from 'react'
import { Paper, CssBaseline, TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import isEmail from 'validator/lib/isEmail'
import logo from '../../assets/images/logo-readymade.png'
import { Alert } from '@material-ui/lab'
import useStore from '../../store'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  login: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '50%',
    maxWidth: theme.breakpoints.values.sm,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mt: {
    marginTop: theme.spacing(3),
  },
  logo: {
    cursor: 'pointer',
    marginBottom: '24px',
    height: '115px',
  },
  errorContainer: {
    marginTop: '24px',
    height: '115px',
    width: '50%',
    maxWidth: theme.breakpoints.values.sm,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
  },
}))

export default () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [valid, setValid] = useState(false)
  const [errorMessage, setErroMessage] = useState(null)

  const login = useStore(state => state.login)

  const history = useHistory()

  const handleSubmit = async evt => {
    evt.preventDefault()
    if (valid) {
      setValid(false)
      const err = await login({ email, password })
      if (err) setErroMessage(err)
      else {
        history.replace('/')
      }
      setValid(true)
    }
  }

  useEffect(() => {
    const isValid = isEmail(email) && password.length > 0
    setValid(isValid)
  }, [email, password])

  return (
    <div className={classes.login}>
      <CssBaseline />
      <img src={logo} className={classes.logo} alt="fireSpot" />
      <form className={classes.form} onSubmit={handleSubmit} noValidate>
        <Paper className={classes.paper}>
          <TextField
            color="secondary"
            label="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            color="secondary"
            className={classes.mt}
            label="Mot de passe"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            disabled={!valid}
            className={classes.mt}
            variant="contained"
            color="secondary"
          >
            Connexion
          </Button>
        </Paper>
      </form>
      <div className={classes.errorContainer}>
        {errorMessage && (
          <Alert className={classes.errorMessage} severity="error">
            {errorMessage}
          </Alert>
        )}
      </div>
    </div>
  )
}
