import React from 'react'
import { Box, FormLabel } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

export default ({ label, mt, children }) => {
  const { breakpoints } = useTheme()
  const maxWidth = `${breakpoints.values.md}px`
  return (
    <Box mt={mt || 6} style={{ width: '100%', maxWidth }}>
      <Box mb={1}>
        <FormLabel component="legend" focused={false}>
          {label}
        </FormLabel>
      </Box>
      {children}
    </Box>
  )
}
