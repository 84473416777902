import React, { useState, useEffect } from 'react'
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  ListItemIcon,
} from '@material-ui/core'
import {
  Menu,
  ExitToApp,
  Person,
  // Feedback as FeedbackIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation, useHistory } from 'react-router-dom'
import { constants } from '@readymade/shared'
import logo from '../../assets/images/logo-readymade.png'
import Breadcrumb from '../Breadcrumb'
import Feedback from '../Feedback'
import useStore from '../../store'

const {
  organizer: { ROLE },
} = constants

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(3),
      width: '100%',
    },
  },
  logo: {
    cursor: 'pointer',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '16px',
    marginBottom: '16px',
    width: '160px',
  },
  profile: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  profileIcon: {
    color: theme.palette.common.black,
    minWidth: theme.spacing(5),
  },
}))

export default ({ props, children }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [feedback, setFeedback] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleClick = to => {
    history.push(to)
    setMobileOpen(false)
  }

  const fetchAll = useStore(state => state.fetchAll)
  useEffect(() => {
    const fetchData = async () => {
      await fetchAll()
    }
    fetchData()
  }, [fetchAll])

  const logout = useStore(state => state.logout)
  const user = useStore(state => state.user)

  const drawer = (
    <div>
      <div width="100%" align="center">
        <img
          src={logo}
          className={classes.logo}
          alt="fireSpot"
          onClick={() => handleClick('/')}
        />
      </div>
      {/* <Logo className={classes.logo} onClick={() => handleClick('/')} /> */}
      <List>
        <ListItem
          button
          selected={pathname.startsWith('/manifestations')}
          onClick={() => handleClick('/manifestations')}
        >
          <ListItemText>Manifestations</ListItemText>
        </ListItem>
        {user?.role === ROLE.ADMIN && (
          <ListItem
            button
            selected={pathname.startsWith('/places')}
            onClick={() => handleClick('/places')}
          >
            <ListItemText>Lieux</ListItemText>
          </ListItem>
        )}
        <ListItem
          button
          selected={pathname.startsWith('/organizers')}
          onClick={() => handleClick('/organizers')}
        >
          <ListItemText>Organisateurs</ListItemText>
        </ListItem>
        {user?.role === ROLE.ADMIN && (
          <ListItem
            button
            selected={pathname.startsWith('/promo')}
            onClick={() => handleClick('/promo')}
          >
            <ListItemText>Manifestation mise en avant</ListItemText>
          </ListItem>
        )}
      </List>
      <List className={classes.profile}>
        {/* {user?.role === ROLE.ADMIN && (
          <ListItem button onClick={e => setFeedback(true)}>
            <ListItemIcon className={classes.profileIcon}>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText primary="Signaler un problème" />
          </ListItem>
        )} */}
        <ListItem component="a" button href={`/organizers/${user?.id}`}>
          <ListItemIcon className={classes.profileIcon}>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Mon profil" />
        </ListItem>
        <ListItem button onClick={e => logout()}>
          <ListItemIcon className={classes.profileIcon}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Déconnexion" />
        </ListItem>
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="default">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap>
            <Breadcrumb />
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      <Feedback open={feedback} onClose={e => setFeedback(false)} />}
    </div>
  )
}
