import React, { useReducer, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  useMediaQuery,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import TextField from '../TextField'
import GeocoderMap from '../GeocoderMap'
import useStore from '../../store'

const DialogView = ({ title, onSubmit, onClose }) => {
  const initialState = {
    name: '',
  }
  const [state, dispatch] = useReducer((state, { type, payload }) => {
    switch (type) {
      case 'name':
        return { ...state, name: payload.name }
      case 'address':
        return {
          ...state,
          address: payload?.address ?? '',
          longitude: payload?.longitude ?? null,
          latitude: payload?.latitude ?? null,
        }
      case 'reset':
        return initialState
      default:
        break
    }
  }, initialState)

  const handleClose = () => {
    dispatch({ type: 'reset' })
    onClose()
  }

  const handleSubmit = () => {
    onSubmit(state)
  }

  const submitDisabled = () => {
    const noName = state.name === ''
    const noAddress = !state.address || !state.longitude || !state.latitude
    return noName || noAddress
  }

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            label="Nom du lieu"
            value={state.name}
            mt={2}
            onChange={e => dispatch({ type: 'name', payload: { name: e } })}
          />
          <GeocoderMap
            onChange={address =>
              dispatch({ type: 'address', payload: { ...address } })
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Annuler
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          disabled={submitDisabled()}
        >
          Valider
        </Button>
      </DialogActions>
    </>
  )
}

export default ({ title, open, onClose, onCreated }) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const [saving, setSaving] = useState(false)
  const createPlace = useStore(state => state.createPlace)

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = async newPlace => {
    setSaving(true)
    const id = await createPlace(newPlace)
    onCreated(id)
    handleClose()
    setSaving(false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={props => (
        <Paper {...props} style={{ width: largeScreen ? '60%' : '100%' }} />
      )}
      maxWidth="md"
    >
      {saving && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={11}
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
        >
          <Typography>Création du lieu...</Typography>
        </Box>
      )}
      <DialogView title={title} onSubmit={handleSubmit} onClose={handleClose} />
    </Dialog>
  )
}
