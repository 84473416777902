import { createTheme } from '@material-ui/core/styles'

const primaryColor = '#FFD700'
const secondaryColor = '#e27743'

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      500: primaryColor,
    },
    secondary: {
      main: secondaryColor,
      500: secondaryColor,
    },
  },
  status: {
    tbc: '#ccfbff',
    programmed: '#d8ff8e',
    notProgrammed: '#ffc1f5',
    event: '#91cbfc',
  },
  feeling: {
    ok: '#2cc68f',
    warn: '#fb8926',
    error: '#ff6969',
  },
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: secondaryColor,
        '&:hover': {
          backgroundColor: secondaryColor,
        },
      },
      current: {
        color: secondaryColor,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: secondaryColor,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: secondaryColor,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: secondaryColor,
      },
      thumb: {
        borderColor: secondaryColor,
      },
      noPoint: {
        backgroundColor: secondaryColor,
      },
    },
  },
})

// console.log(theme)

export default theme
