import React, { useState, useRef, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { IconButton } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatClear,
} from '@material-ui/icons'
import Input from '../Input'

const icons = Quill.import('ui/icons')
icons.bold = null
icons.italic = null
icons.underline = null
icons.clean = null

const CustomToolbar = ({ toolbarId, length, maxLength }) => {
  const {
    spacing,
    feeling: { ok, warn, error },
  } = useTheme()
  const warnLength = maxLength - 100
  const color = length <= warnLength ? ok : length <= maxLength ? warn : error
  return (
    <div
      id={toolbarId}
      style={{
        border: '1px solid #ccc',
        borderBottom: 'unset',
        padding: spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <IconButton size="small" className="ql-bold">
          <FormatBold />
        </IconButton>
        <IconButton size="small" className="ql-italic">
          <FormatItalic />
        </IconButton>
        <IconButton size="small" className="ql-underline">
          <FormatUnderlined />
        </IconButton>
        <IconButton
          size="small"
          className="ql-clean"
          style={{ marginLeft: spacing(3) }}
        >
          <FormatClear />
        </IconButton>
      </div>
      <span style={{ justifySelf: 'flex-end', color }}>
        {length ?? '...'}/{maxLength}
      </span>
    </div>
  )
}

export default ({
  toolbarId,
  label,
  mt,
  value,
  readOnly,
  maxLength,
  onChange,
}) => {
  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
    },
  }
  const formats = ['bold', 'italic', 'underline']

  const [length, setLength] = useState()

  const quillEditor = useRef(null)
  useEffect(() => {
    if (quillEditor.current) {
      const editor = quillEditor.current.getEditor()
      const unprivilegedEditor = quillEditor.current.makeUnprivilegedEditor(
        editor
      )
      setLength(unprivilegedEditor.getLength() - 1)
    }
  }, [quillEditor])

  const handleChange = (value, delta, source, editor) => {
    onChange(value)
    setLength(editor.getLength() - 1)
  }
  return (
    <Input label={label} mt={mt} className="quill-fr">
      <CustomToolbar
        toolbarId={toolbarId}
        length={length}
        maxLength={maxLength}
      />
      <ReactQuill
        ref={quillEditor}
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        readOnly={readOnly}
      />
    </Input>
  )
}
