import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Table from '../Table'

export default ({ open, availableOrganizers, onClose, onAdded }) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const [additionalOrganizers, setAdditionalOrganizers] = useState([])

  const handleSelectionChange = rows => {
    setAdditionalOrganizers(rows.map(r => r.id))
  }

  const handleClose = () => {
    setAdditionalOrganizers([])
    onClose()
  }

  const handleSubmit = () => {
    onAdded(additionalOrganizers)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={props => (
        <Paper {...props} style={{ width: largeScreen ? '60%' : '100%' }} />
      )}
      maxWidth="md"
    >
      <DialogTitle>
        Sélectionner un ou des organisateurs pour cette manifestation
      </DialogTitle>
      <DialogContent>
        <Table
          columns={[
            { title: 'Nom', field: 'organizerName' },
            { title: 'Email', field: 'organizerEmail' },
            { title: 'Téléphone', field: 'organizerPhone' },
          ]}
          data={availableOrganizers.map((o, i) => ({
            id: o.id,
            organizerName: o.organizerName,
            organizerEmail: o.organizerEmail,
            organizerPhone: o.organizerPhone,
            tableData: {
              checked: additionalOrganizers.includes(o.id),
            },
          }))}
          options={{
            selection: true,
            pageSize: 10,
            pageSizeOptions: [],
            emptyRowsWhenPaging: true,
            padding: 'dense',
          }}
          onSelectionChange={handleSelectionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Annuler
        </Button>
        <Button
          onClick={handleSubmit}
          color="secondary"
          disabled={additionalOrganizers.length === 0}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}
