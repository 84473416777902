import create from 'zustand'
import { API } from '@readymade/shared'

const api = new API(process.env.REACT_APP_API_URL)

const log = config => (set, get, api) =>
  config(
    args => {
      // console.log('  applying', args)
      set(args)
      // console.log('  new state', get())
    },
    get,
    api
  )

const [useStore] = create(
  log((set, get) => ({
    user: null,
    manifestations: [],
    places: [],
    events: [],
    organizers: [],
    loading: true,
    async login(data) {
      const { user, token, err } = await api.login(data)
      if (!err) {
        localStorage.setItem('token', token)
        set({ user })
      }
      return err
    },
    logout() {
      set({ user: null })
      localStorage.removeItem('token')
    },
    async fetchUser() {
      const { user, err } = await api.tokenAuth()
      if (!user || err) get().logout()
      else set({ user })
    },
    async fetchAll() {
      set({ loading: true })
      await get().fetchUser()
      if (get().user) {
        const [manifestations, places, events, organizers] = await Promise.all([
          api.getAllManifestations(),
          api.getAllPlaces(),
          api.getAllEvents(),
          api.getAllOrganizers(),
        ])
        set({ manifestations, places, events, organizers, loading: false })
      } else set({ loading: false })
    },
    async fetchEvents() {
      const events = await api.getAllEvents()
      set({ events })
    },
    // manifestations
    async createManifestation(manifestation) {
      try {
        const newManifestation = await api.createManifestation(manifestation)
        const updatedManifestations = get().manifestations
        updatedManifestations.push(newManifestation)
        set({ manifestations: updatedManifestations })
        return newManifestation.id
      } catch (err) {
        get().logout()
      }
    },
    async deleteManifestation(manifestationId) {
      try {
        const { id, events } = await api.deleteManifestation(manifestationId)
        // remove deleted manifestation from the store
        const updatedManifestations = get().manifestations.filter(
          m => m.id !== id
        )
        // remove associated events from the store
        const updatedEvents = get().events.filter(e => !events.includes(e.id))
        set({ manifestations: updatedManifestations, events: updatedEvents })
      } catch (err) {
        get().logout()
      }
    },
    async updateManifestation(manifestation, keepImage = false) {
      try {
        const updatedManifestation = await api.updateManifestation(
          manifestation,
          keepImage
        )
        const updatedManifestations = Array.from(get().manifestations)
        const index = updatedManifestations.findIndex(
          m => m.id === updatedManifestation.id
        )
        updatedManifestations[index] = updatedManifestation
        set({ manifestations: updatedManifestations })
      } catch (err) {
        get().logout()
      }
    },
    // events
    async createEvent(event) {
      try {
        const newEvent = await api.createEvent(event)
        const updatedEvents = get().events
        updatedEvents.push(newEvent)
        set({ events: updatedEvents })
        return newEvent.id
      } catch (err) {
        get().logout()
      }
    },
    async deleteEvent(eventId) {
      try {
        const { id } = await api.deleteEvent(eventId)
        const updatedEvents = get().events.filter(e => e.id !== id)
        set({ events: updatedEvents })
      } catch (err) {
        get().logout()
      }
    },
    async updateEvent(event) {
      try {
        const updatedEvent = await api.updateEvent(event)
        const updatedEvents = Array.from(get().events)
        const index = updatedEvents.findIndex(m => m.id === updatedEvent.id)
        updatedEvents[index] = updatedEvent
        set({ events: updatedEvents })
      } catch (err) {
        get().logout()
      }
    },
    // places
    async createPlace(place) {
      try {
        const newPlace = await api.createPlace(place)
        const updatedPlaces = get().places
        updatedPlaces.push(newPlace)
        set({ places: updatedPlaces })
        return newPlace.id
      } catch (err) {
        get().logout()
      }
    },
    async deletePlace(placeId) {
      try {
        const { id } = await api.deletePlace(placeId)
        const updatedPlaces = get().places.filter(p => p.id !== id)
        // remove deleted place from the manifestations
        const updatedManifestations = get().manifestations.map(m => {
          return { ...m, places: m.places.filter(p => p !== id) }
        })
        set({ places: updatedPlaces, manifestations: updatedManifestations })
      } catch (err) {
        get().logout()
      }
    },
    async updatePlace(place) {
      try {
        const updatedPlace = await api.updatePlace(place)
        const updatedPlaces = Array.from(get().places)
        const index = updatedPlaces.findIndex(p => p.id === updatedPlace.id)
        updatedPlaces[index] = updatedPlace
        set({ places: updatedPlaces })
      } catch (err) {
        get().logout()
      }
    },
    // organizers
    async createOrganizer(organizer) {
      try {
        const newOrganizer = await api.createOrganizer(organizer)
        const updatedOrganizers = get().organizers
        updatedOrganizers.push(newOrganizer)
        set({ organizers: updatedOrganizers })
        return newOrganizer.id
      } catch (err) {
        get().logout()
      }
    },
    async deleteOrganizer(organizerId) {
      try {
        const { id } = await api.deleteOrganizer(organizerId)
        const updatedOrganizers = get().organizers.filter(p => p.id !== id)
        // remove deleted organizer from the manifestations
        const updatedManifestations = get().manifestations.map(m => {
          return { ...m, organizers: m.organizers.filter(p => p !== id) }
        })
        set({
          organizers: updatedOrganizers,
          manifestations: updatedManifestations,
        })
      } catch (err) {
        get().logout()
      }
    },
    async updateOrganizer(organizer) {
      try {
        const updatedOrganizer = await api.updateOrganizer(organizer)
        const updatedOrganizers = Array.from(get().organizers)
        const index = updatedOrganizers.findIndex(
          p => p.id === updatedOrganizer.id
        )
        updatedOrganizers[index] = updatedOrganizer
        set({ organizers: updatedOrganizers })
      } catch (err) {
        get().logout()
      }
    },
    // issues
    async createIssue(issue) {
      try {
        const user = get().user.email
        await api.createIssue({ ...issue, user })
      } catch (err) {
        console.log(err)
      }
    },
    // newsletter
    async generateNewsletter(year, month, promotedId) {
      try {
        const text = await api.generateNewsletter(year, month, promotedId)
        return text
      } catch (err) {
        console.log(err)
      }
    },
  }))
)

export default useStore
