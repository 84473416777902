export const event = {
  TYPE: {
    VERNISSAGE: 'Vernissage',
    FINISSAGE: 'Finissage',
    CONCERT: 'Concert',
    PERFORMANCE: 'Performance',
    RENCONTRE: 'Rencontre',
    CONFERENCE: 'Conférence',
    VISITE: 'Visite',
    DERNIER_JOUR: 'Dernier jour',
    LANCEMENT: "Lancement d'édition",
    SIGNATURE: 'Signature',
    PROJECTION: 'Projection',
    WORKSHOP: 'Workshop',
    ATELIER: 'Atelier',
    INAUGURATION: 'Inauguration',
  },
}
