import React from 'react'
import { Box, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 72,
    right: 34,
    padding: 0,
    textAlign: 'inherit',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      right: 0,
      padding: 10,
      textAlign: 'end',
    },
  },
}))

export default ({ item }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleNewClick = () => {
    history.push(`/${item}s/new`)
  }

  return (
    <Box className={classes.root}>
      <Fab color="primary" onClick={handleNewClick}>
        <Add />
      </Fab>
    </Box>
  )
}
