import { useState, useMemo } from 'react'
import Fuse from 'fuse.js'

const fuzzySearch = ({ fuse, data, term }) => {
  const result = fuse.search(`${term}`)
  return term ? result : data
}

export default ({ data, options }) => {
  const [term, setTerm] = useState('')

  const fuseOptions = {
    threshold: 0.2,
    ...options,
  }

  const fuse = useMemo(() => new Fuse(data, fuseOptions), [data, fuseOptions])

  const result = fuzzySearch({ data, term, fuse })

  const reset = () => setTerm('')

  return { result, search: setTerm, term, reset }
}
