import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { format, isAfter, parse } from 'date-fns'
import { utils } from '@readymade/shared'

import Table from '../../components/Table'
import useStore from '../../store'
import NewItem from '../../components/NewItem'

const { getManifestationColor, temporalSort } = utils

export default () => {
  const { breakpoints, status } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  const history = useHistory()

  // load from store
  const manifestations = useStore(state => state.manifestations)
  const places = useStore(state => state.places)

  // handle columns display according to the screen size
  const columns = []
  if (largeScreen) columns.push({ title: '#', field: 'id' })
  columns.push({ title: 'Titre', field: 'title' })
  columns.push({
    title: 'Début',
    field: 'start',
    customSort: (a, b) =>
      isAfter(
        parse(a.start, 'dd/MM/yyyy', new Date()),
        parse(b.start, 'dd/MM/yyyy', new Date())
      )
        ? 1
        : -1,
  })
  columns.push({
    title: 'Fin',
    field: 'end',
    customSort: (a, b) =>
      isAfter(
        parse(a.end, 'dd/MM/yyyy', new Date()),
        parse(b.end, 'dd/MM/yyyy', new Date())
      )
        ? 1
        : -1,
  })
  if (largeScreen) columns.push({ title: 'Lieux', field: 'places' })
  columns.push({
    title: 'Statut',
    field: 'status',
    cellStyle(value) {
      const color = getManifestationColor(value, status)
      return { backgroundColor: color }
    },
  })

  const permanentColumns = Array.from(columns)
  if (largeScreen) permanentColumns.splice(2, 2)
  else permanentColumns.splice(1, 2)

  // display options
  const options = {
    padding: largeScreen ? 'default' : 'dense',
  }

  // handle data for all tables
  const {
    current = [],
    upcoming = [],
    past = [],
    permanent = [],
  } = temporalSort(manifestations)

  const formatManifestations = manifestations =>
    manifestations.map(m => {
      const currentPlaces =
        m?.places
          ?.map(p => places.find(place => place.id === p).name)
          .join(', ') ?? ''
      return {
        id: m.id,
        title: m.title,
        start: m.start ? format(m.start, 'dd/MM/yyyy') : '',
        end: m.end ? format(m.end, 'dd/MM/yyyy') : '',
        places: currentPlaces,
        status: m.status,
      }
    })

  const currentManifestations = formatManifestations(current)
  const upcomingManifestations = formatManifestations(upcoming)
  const pastManifestations = formatManifestations(past)
  const permanentManifestations = formatManifestations(permanent)

  // handle row click
  const handleRowClick = (event, rowData) => {
    history.push(`/manifestations/${rowData.id}`)
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary">
        <Tab label="En cours" />
        <Tab label="À venir" />
        <Tab label="Passées" />
        <Tab label="Pérennes/POI" />
      </Tabs>
      {value === 0 && (
        <Table
          columns={columns}
          data={currentManifestations}
          options={options}
          onRowClick={handleRowClick}
        />
      )}
      {value === 1 && (
        <Table
          columns={columns}
          data={upcomingManifestations}
          options={options}
          onRowClick={handleRowClick}
        />
      )}
      {value === 2 && (
        <Table
          columns={columns}
          data={pastManifestations}
          options={options}
          onRowClick={handleRowClick}
        />
      )}
      {value === 3 && (
        <Table
          columns={permanentColumns}
          data={permanentManifestations}
          onRowClick={handleRowClick}
        />
      )}
      <NewItem item="manifestation" />
    </>
  )
}
