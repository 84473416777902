import React from 'react'
import Input from '../Input'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { constants } from '@readymade/shared'

const {
  manifestation: { TYPE },
} = constants

export default ({ value, readOnly, onChange }) => {
  const { breakpoints } = useTheme()
  const largeScreen = useMediaQuery(breakpoints.up('sm'))
  return (
    <Input label="Type de manifestation">
      <RadioGroup
        value={value}
        onChange={e => !readOnly && onChange(e.target.value)}
        row={largeScreen}
      >
        {Object.values(TYPE).map((t, i) => (
          <FormControlLabel
            key={i}
            value={t}
            control={
              <Radio
                color={readOnly ? 'default' : 'secondary'}
                disableRipple={readOnly}
              />
            }
            label={t}
          />
        ))}
      </RadioGroup>
    </Input>
  )
}
